import { apiFetcherLoggedIn } from './fetcher';


let STRINGS = {
  TITLE1_HOME: 'Sélectionnez votre prestation de nettoyage',
  TITLE2_HOME: 'Nos prestations 24h/24 en 30 minutes chez vous',
  PRESSING_LBL: 'PRESSING',
  KILO_LBL: 'LINGE AU KILO',
  BLANCHISSERIE_LBL: 'BLANCHISSERIE',
  SNEAKERS_LBL: 'SNEAKERS',
  RETOUCHES_LBL: 'RETOUCHES',
  CUIR_LBL: 'PEAU ET CUIR',
  MA_COMMANDE: 'Ma commande',
  POURSUIVRE: 'POURSUIVRE MA COMMANDE',
  COMMENTAIRE: 'Commentaire sur la commande',
  RECAPITULATIF: 'Récapitulatif',
  DELAI_NORMAL: 'Normal (48h)',
  DELAI_EXPRESS: 'Express (24h) + 20€',
  DELAI_ALLONGE: 'Allongé (>48h)',  
  COMMANDE_VALIDEE: 'Votre commande est validée !',
  CONSEIL1: 'Votre livreur viendra récupérer vos vêtements le %PICKUP_DATE%, soyez prêt !',
  CONSEIL2: 'Merci de mettre vos vêtements dans un sac, avec votre nom.',
  CONSEIL3: 'Votre linge sera livré chez vous le %DELIVERY_DATE%, soyez disponible.',
  QUESTION: 'Une question ?',
};

function removeHtmlComponent(str) {
  return str.replaceAll("<p>", "").replaceAll("</p>", "");
}

async function loadAllConfigMessages() {
  await apiFetcherLoggedIn("/config/message", "GET").then((allMessages) => {
    for(let message of allMessages){
      if(message.reference in STRINGS){
        STRINGS[message.reference] = removeHtmlComponent(message.value);
      }
    }
  })
}
loadAllConfigMessages();


export default STRINGS;
