import { apiFetcher } from "../utils/fetcher";
import { geocodeByPlaceId } from 'react-google-places-autocomplete';
import moment from 'moment-timezone';
import momentFr from 'moment/locale/fr';
import toast from "react-hot-toast";

export const isLoggedIn = () => {
    return localStorage.getItem("account") !== null && getStoredAccount().token && getStoredAccount().id && getStoredAccount().type ;
}

export const getStoredAccount = () => {
    return JSON.parse(localStorage.getItem("account"));
}

export const setStoredAccount = (account) => {
    return localStorage.setItem("account", JSON.stringify(account));
}

export const removeStoredAccount = () => {
    return localStorage.removeItem("account");
}


/*
forme : list of
{
    count: int,
    product: list
}
*/

export const getStoredBasket = () => {
    return localStorage.getItem("basket") === null ? [] : JSON.parse(localStorage.getItem("basket"));
}

export const setStoredBasket = (basket) => {
    return localStorage.setItem("basket", JSON.stringify(basket));
}

export const clearBasket = () => {
    setStoredBasket([]);
}

export const getInBasket = (product) => {
    if(isInBasket(product)){
        return getStoredBasket().filter(e => e.product.id == product.id)[0];
    }
    else {
        return {};
    }
}

export const isInBasket = (product) => {
    return getStoredBasket().filter(e => e.product.id == product.id).length > 0;
}

export const addToBasket = (product, count) => {
    let basket = getStoredBasket();
    if(isInBasket(product)){
        basket = basket.map(e => {
            if(e.product.id == product.id){
                e.count = e.count + count;
            }
            return e;
        })
    }
    else {
        basket.push({
            product: product,
            count: count
        })
    }
    setStoredBasket(basket);
}

export const removeToBasket = (product, count) => {
    let totalCount = -count;
    if(isInBasket(product)){
        totalCount = totalCount + getInBasket(product).count;
    }

    let basket = getStoredBasket();
    basket = basket.map(e => {
        if(e.product.id == product.id){
            e.count = totalCount;
        }
        return e;
    })
    basket = basket.filter(e => e.count > 0);
    setStoredBasket(basket);
}

export const removeAllToBasket = (product) => {
    let basket = getStoredBasket();
    basket = basket.filter(e => e.product.id != product.id);
    setStoredBasket(basket);
}



// VALIDITY FORMAT TESTERS

export const isEmailValid = (text) => {
    const nameRegex = new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/i);
    return nameRegex.test(text)
}

export const isSiretValid = (text) => {
    const siretRegex = new RegExp(/\d{14}/g);
    return siretRegex.test(text)
}

export const isPasswordValid = (text) => {
    const nameRegex = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/i);
    return nameRegex.test(text)
}

export const isPhoneNumberValid = (text) => {
    const phoneRegex = new RegExp(/^(06|07)[0-9]{8}$/i);
    return phoneRegex.test(text)
}


export const distanceCoordinates = (lat1, lon1, lat2, lon2, unit) => {
    if ((lat1 == lat2) && (lon1 == lon2)) {
        return 0;
    }
    else {
        var radlat1 = Math.PI * lat1/180;
        var radlat2 = Math.PI * lat2/180;
        var theta = lon1-lon2;
        var radtheta = Math.PI * theta/180;
        var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
        if (dist > 1) {
            dist = 1;
        }
        dist = Math.acos(dist);
        dist = dist * 180/Math.PI;
        dist = dist * 60 * 1.1515;
        if (unit=="K") { dist = dist * 1.609344 }
        if (unit=="N") { dist = dist * 0.8684 }
        return dist;
    }
}

export const isValidHttpCode = (httpCode) => {
    return httpCode >= 200 && httpCode < 400;
}


export const login = (email, password) => {
    let compte = {
        email: email,
        password: password,
    }

    return apiFetcher("/users/login", "POST", compte)
      .then(response => {
        if (response.body.token && response.body.id && response.body.type) {
          return response;
        } else {
          return Promise.reject();
        }
      });
}


export const askResetPassword = (email) => {

    return apiFetcher("/resetPasswords/"+email, "POST")
      .then(response => {
        if (response.body.message) {
          return response;
        } else {
          return Promise.reject();
        }
      });
}

export const resetPassword = (token, password) => {
    return apiFetcher("/resetPasswords/"+token+"/reset", "PUT", {password: password})
      .then(response => {
        if (response.body.message) {
          return response;
        } else {
          return Promise.reject();
        }
      });
}

export const isIndividualAccount = (account) => {
    return account && account.type && account.type.localeCompare("individual") == 0;
}

export const mustBeLoggedIn = () => {
    if(!isLoggedIn()){
        window.location.href = "/login";
    }
}

export const leadToOrdersClosed = (isOpenVariable) => {
    if(isOpenVariable.value.localeCompare("false") === 0){
        window.location.href = "/order/closed";
    }
}


export const roundPrice = (price) => {
    return Math.round(price*100)/100;
}

export const formatAddress = async (address, address2, address3, wording) => {

    return geocodeByPlaceId(address.value.place_id)
    .then(place => {
      const { long_name: postalCode = '' } =
      place[0].address_components.find(c => c.types.includes('postal_code')) || {};

      let structured_formatting = address.value.structured_formatting;

      const address1 = structured_formatting.main_text
      const city = structured_formatting.secondary_text

      return {
        adress1: address1,
        adress2: address2,
        adress3: address3,
        postalCode: postalCode,
        city: city,
        wording: wording
      };
    })
    
}


export const formatDateToDaySentence = (date) => {
    const days = ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"];
    const months = ["Jan", "Fev", "Mar", "Avr", "Mai", "Jun", "Jul", "Aou", "Sep", "Oct", "Nov", "Dec"];

    let dayDigit = date.getDay()
    let weekDay = days[dayDigit]

    let monthDigit = date.getMonth()
    let month = months[monthDigit]

    let year = date.getYear()+1900

    let dayOfMonth = date.getDate()

    return weekDay+". "+dayOfMonth+" "+month+" "+year;  
}

export const formatDateToHoursAndMinutes = (date) => {
  let hours = date.getHours();
  let minutes = date.getMinutes() == 0 ? '00' : (date.getMinutes() < 10 ? '0'+date.getMinutes() : date.getMinutes());
  
  return hours+":"+minutes;  
}

export const validStuartSlot = (date, stringManager, type) => {
  let hours = date.getHours();
  let minutes = date.getMinutes();

  let dayOfWeek = date.getDay();

    let openingHour = stringManager.getTimetable(dayOfWeek)[type].opening.hour;
    let openingMinute = stringManager.getTimetable(dayOfWeek)[type].opening.minute;
    let closingHour = stringManager.getTimetable(dayOfWeek)[type].closing.hour;
    let closingMinute = stringManager.getTimetable(dayOfWeek)[type].closing.minute;

    console.log(openingHour, openingMinute, closingHour, closingMinute)

    return !(hours < openingHour || (hours == openingHour && minutes < openingMinute) 
            || hours > closingHour || (hours == closingHour && minutes > closingMinute));
  

  //return !(hours < 9 || (hours < 9 && minutes < 30));
}

export const areSameDay = (date1, date2) => {
    return date1 && date2 && date1.getDate() == date2.getDate() && date1.getMonth() == date2.getMonth() && date1.getFullYear() == date2.getFullYear();
}

export const formatStringToPrint = (str, data) => {
    for(let key of Object.keys(data)){
        str = str.replace(new RegExp("%"+key+"%", "g"), data[key]);
    }
    str = str.replace(new RegExp("%LINE%", "g"), "");
    return str;
  }


export const getUTCTimestamp = () => {
    return moment().unix();
}

export const timestampToStrDate = (timestamp) => {
    return moment(timestamp, 'X').tz("Europe/Paris").locale('fr').format('ddd D MMM YYYY à HH:mm');
}

export const timestampToStrDay = (timestamp) => {
    return moment(timestamp, 'X').tz("Europe/Paris").locale('fr').format('ddd D MMM');
}

export const timestampToStrHour = (timestamp) => {
    return moment(timestamp, 'X').tz("Europe/Paris").locale('fr').format('HH:mm');
}

export const dateToTimestamp = (date) => {
    return date.getTime()/1000;
}

export const timestampToDate = (timestamp) => {
    return moment(timestamp, "X").tz("Europe/Paris").toDate();
}

export const timestampToStrIso = (timestamp) => {
    return moment(timestamp, "X").tz("Europe/Paris").toISOString();
}