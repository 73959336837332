import Template from "../components/template";
import PageSection from "../components/pageSection";
import './../resources/css/global.css';
import './../resources/css/colors.css';
import './../resources/css/orderSummary.css';
import './../resources/css/modal.css';
import HorizontalGroup from "../components/horizontalGroup";
import TimeSlot from "../components/timeSlot";
import CreditCard from "../components/creditCard";
import VerticalGroup from "../components/verticalGroup";
import SimpleTextButton from "../components/simpleTextButton";
import Address from "../components/address";
import RadioButton from "../components/radioButton";
import { useState } from 'react';
import Modal from 'react-modal';
import { areSameDay, clearBasket, distanceCoordinates, formatAddress, formatDateToDaySentence, formatDateToHoursAndMinutes, getStoredAccount, getStoredBasket, mustBeLoggedIn, roundPrice, setStoredBasket, validStuartSlot, dateToTimestamp, getUTCTimestamp, leadToOrdersClosed } from "../utils/methods";
import { apiFetcher, apiFetcherLoggedIn } from "../utils/fetcher";
import InputText from "../components/inputText";
import { geocodeByAddress, getLatLng } from "react-google-places-autocomplete";
import { CENTER_LAT, CENTER_LNG, MAX_RADIUS, WEBSITE_URL } from "../utils/config";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import moment from 'moment-timezone';

import StringsManager from "../utils/stringsManager";
import SplashScreen from "../components/splashScreen";
import React, {useEffect} from "react";

export default class OrderSummary extends React.Component {

  constructor(props) {
    super(props);
    this.stringManager = StringsManager.getInstance();

    this.state = {
      appIsReady: false,

      loading: false,
      pickupAddress: null,
      billingAddress: null,
      paymentMethod: null,
      pickupSlot: null,
      deliverySlot: null,
      comment: "",

      modalAA_open: false,
      modalAA_address: "",
      modalAA_address2: "",
      modalAA_address3: "",
      modalAA_wording: "",

      modalLA_open: false,
      modalLA_addresses: [],

      modalLS_open: false,
      modalLS_mode: "pickup",
      modalLS_selectedDay: null,
      modalLS_days: [],
      modalLS_slots_pickup_all: [],
      modalLS_slots_delivery_all: [],
      modalLS_slots_current: [],

      modalLC_open: false,
      modalLC_cards: [],

      minDelay: 10,

      subscriptionRemainingMoney: 0,

      paymentMethods: [],

      deliveryInfos: {
        type: "bike",
        price: 0,
        min_for_free: 100,
        decidedPrice: 0
      },

      delais: [
        {
          title: 'normal',
          selected: false,
          disabled: false,
          gap: 2
        },
        {
          title: 'express',
          selected: false,
          disabled: false,
          gap: 1
        },
        {
          title: 'allonge',
          selected: true,
          disabled: false,
          gap: 3
        }],
    }

    this.modalLA_callback = () => {};
    this.modalLS_callback = () => {};

    this.account = getStoredAccount();
    this.basket = getStoredBasket();
    this.totalPriceTTC = 0;
    for(let article of this.basket){
      this.totalPriceTTC = this.totalPriceTTC + article.count * article.product.price;
    }

    this.modalStyle = {
      overlay: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 9999
      },
      content: {
        position: "relative",
        width: 600,
        marginBottom: "70px"
      }
    };

    //this.fastDeliveryPrice = 20;


    apiFetcherLoggedIn("/users/"+this.account.id, "GET").then(userInfos => {
      this.setState({
        subscriptionRemainingMoney: userInfos.body.subscriptionRemainingMoney,
      })
    });

    apiFetcherLoggedIn("/orders/getDeliveryInfosByBasket", "POST", {basket: this.basket}).then(infos => {
      this.setState({
        deliveryInfos: infos.body,
      })
    });

  }

  addAddress = async (callback) => {
    let errors = [];

    if(this.state.modalAA_address && this.state.modalAA_address.label.length > 0){
      await geocodeByAddress(this.state.modalAA_address.label)
      .then(results => getLatLng(results[0]))
      .then(({ lat, lng }) => {
          const dist = distanceCoordinates(lat, lng, CENTER_LAT, CENTER_LNG, "K")*1000;
          if(dist > MAX_RADIUS) {
            errors.push("L'adresse de livraison doit se trouver dans Paris.");
          }
        }
      );
    }
    else {
      errors.push("Veuillez renseigner une adresse de livraison.")
    }

    if(this.state.modalAA_address.length == 0) {
      errors.push("Veuillez nommer l'adresse de livraison.")
    }

    if(errors.length > 0){
      for(let error of errors){
        toast.error(error, {
          position: "bottom-center"
        });
      }
    }
    else {
      apiFetcherLoggedIn("/address", "POST", await formatAddress(this.state.modalAA_address, this.state.modalAA_address2, this.state.modalAA_address3, this.state.modalAA_wording))
      .then(responsePost => {
        callback();
      })
    }

  }

  openCardsListModal = () => {
    this.closeAllModals();
    this.setState({
      loading: false,
      modalLC_cards: this.state.paymentMethods,
      modalLC_open: true
    })
  }

  _initPaymentMethods = () => {
    this.setState({loading: true})
    return apiFetcherLoggedIn("/orders/listPaymentMethods", "GET")
      .then(paymentMethods => {
        this.setState({loading: false, paymentMethods: paymentMethods.body.data}, () => this._initCreditCard());
      })
  }

  _initCreditCard = () => {
    if(this.state.paymentMethods.length > 0){
      let paymentMethod = this.state.paymentMethods[0];
      this.setState({paymentMethod: paymentMethod});
    }
  }

  openAddressAddModal = () => {
    this.closeAllModals();
    this.setState({modalAA_open: true})
  }

  openCardsAddModal = () => {
    this.setState({loading: true})
    this.closeAllModals();
    apiFetcherLoggedIn("/orders/createCheckout", "POST", {
      successUrl: WEBSITE_URL+"/order/summary",
      cancelUrl: WEBSITE_URL+"/order/summary"
    })
      .then(response => {
        this.setState({loading: false})
        const paymentURL = response.body.url;
        window.location.href = paymentURL;
      })
  }

  openAddressListModal = (callback) => {
    this.setState({loading: true})
    apiFetcherLoggedIn("/users/"+this.account.id+"/address", "GET")
    .then(addresses => {
      this.closeAllModals();
      this.setState({
        loading: false,
        modalLA_addresses: addresses.body,
        modalLA_open: true
      })
      this.modalLA_callback = callback;
    })
  }

  closeAllModals = () => {
    this.setState({
      modalAA_open: false,
      modalLA_open: false,
      modalLC_open: false,
      modalLS_open: false
    })
  }

  openSlotsListModal = (mode, callback) => {
    this.setState({
      loading: true,
      modalLS_mode: mode,
    })
    this.modalLS_callback = callback;
    if(mode.localeCompare("pickup") == 0){
      this.setState({modalLS_days: this._generateNext7daysFrom()});
    }
    else {
      let weekendDelay = 0; // Si la prise en charge est le weekend, on rajoute 24h de delai car le dimanche est un jour fermé
      if(this.state.pickupSlot.getDay() == 0 || this.state.pickupSlot.getDay() == 6){ // Si c'est le dimanche ou le samedi
        weekendDelay = 1;
      }
      this.setState({modalLS_days: this._generateNext7daysFrom(Math.max(this.state.minDelay + weekendDelay, this.state.delais.filter(e => e.selected)[0].gap + weekendDelay), this.state.pickupSlot)});
    }
    this.setState({modalLS_selectedDay: null})
    this._getStuartSlots("pickup").then(pickupSlots => {
      this._getStuartSlots("dropoff").then(deliverySlots => {
        this.setState({
          loading: false,
          modalLS_slots_pickup_all: pickupSlots,
          modalLS_slots_delivery_all: deliverySlots,
          modalLS_slots_current: [],
          modalLS_open: true
        })
      });
    });
  }

  _generateNext7daysFrom = (gapDay=0, from=new Date()) => {
    let next7days = [];
    for (let i = 0; i < 7; ++i) {
      let newDay = new Date(from.getTime());
      newDay.setDate(from.getDate() + i + gapDay);
      next7days.push(newDay);
    }
    return next7days;
  };

  _getStuartSlots = async (type="pickup") => {
    let date = new Date();
    date.setDate(date.getDate()+1);
    let year = date.getFullYear();
    let month = date.getMonth();
    month++;
    let day = date.getDate();
    let dayStr = day;
    let monthStr = month;
    if (day < 10) dayStr = '0' + day;
    if (month < 10) monthStr = '0' + month;
  
    return apiFetcherLoggedIn(`/orders/stuartSlots/?type=${type}&date=${year}-${monthStr}-${dayStr}`, "GET")
      .then(response => {
        response = response.body;
        /*response = response.filter((e) => {
          let date = new Date(e.start_time);
          return validStuartSlot(date);
        })*/
        return response;
      })
      
  };

  _loadDaySlots = (date) => {
    let slots = this.state.modalLS_slots_pickup_all;
    if(this.state.modalLS_mode.localeCompare("delivery") == 0){
      slots = this.state.modalLS_slots_delivery_all;
    }
    let stuartSlotsFiltered = slots
      .map((slot) => {
        let start = new Date(slot.start_time);
        let end = new Date(slot.end_time);
        start.setFullYear(date.getFullYear());
        end.setFullYear(date.getFullYear());
        start.setMonth(date.getMonth());
        end.setMonth(date.getMonth());
        start.setDate(date.getDate());
        end.setDate(date.getDate());
        return {
          start_time: start,
          end_time: end
        }
      })
      .filter((slot) => validStuartSlot(new Date(slot.start_time), this.stringManager, this.state.modalLS_mode))
      .filter((slot) => {
        let startTime = new Date(slot.start_time);
        let startDate = date;
        let currentDate = new Date();
        return (startDate.getDate() !== currentDate.getDate() || startDate.getMonth() !== currentDate.getMonth() || startDate.getFullYear() !== currentDate.getFullYear()) || startTime.getHours() > currentDate.getHours() || (startTime.getHours() === currentDate.getHours() && startTime.getMinutes() > currentDate.getMinutes())
      });

    if(this.state.modalLS_mode.localeCompare("delivery") == 0){
        stuartSlotsFiltered = stuartSlotsFiltered
      .filter((slot) => { // on supprime les horaires à moins de H-24, H-48, ...
        let slotDate = new Date(slot.start_time);
        let firstDate = this.state.modalLS_days[0];
        if(areSameDay(date, firstDate)){
            return this.state.pickupSlot.getHours() < slotDate.getHours() || (this.state.pickupSlot.getHours() == slotDate.getHours() && this.state.pickupSlot.getMinutes() <= slotDate.getMinutes())
        }
        else {
          return true;
        }
      });
    }
    else {
      stuartSlotsFiltered = stuartSlotsFiltered
      .filter((slot) => {
        let currentDate = new Date();
        let slotDate = new Date(slot.start_time);
        //return true;
        return Math.abs(currentDate.getTime() - slotDate.getTime()) > 2*60*60*1000;
      });
    }
    
    this.setState({
      modalLS_slots_current: stuartSlotsFiltered
    })
  }

  _alertMinDelay = (delay) => {
    toast.error(`Le délai de traitement minimum est de ${delay} jours.`, {
      position: "bottom-center"
    });
  }

  _confirmOrder = async () => {
    this.setState({loading: true})
    
    let isDeliveryAddressValid = await apiFetcher("/utils/isAddressValid", "POST", {
      address: this.state.pickupAddress.adress1,
      postalCode: this.state.pickupAddress.postalCode,
      city: this.state.pickupAddress.city,
    });
    if(!isDeliveryAddressValid.body.isValid){
      toast.error('Cette adresse de livraison n\'est pas couverte par nos services.', {
        position: "bottom-center"
      });
      this.setState({loading: false})
      return;
    }



    if(moment(getUTCTimestamp(), "X").isAfter(moment(dateToTimestamp(this.state.pickupSlot), "X"))){
      toast.error("Le créneau de prise en charge est déjà passé.", {
        position: "bottom-center"
      });
      this.setState({loading: false})
      return;
    }
    
    apiFetcherLoggedIn("/orders", "POST", 
      {
        basket: this.basket.map(e => {e.item = e.product; return e}),
        pickUpAddressId: this.state.pickupAddress.id,
        billingAddressId: this.state.billingAddress.id,
        pickUpDate: dateToTimestamp(this.state.pickupSlot),
        deliveredDate: dateToTimestamp(this.state.deliverySlot),
        comments: this.state.comment,
        paymentMethodId: this.state.paymentMethod.id,
        //fastDelivery: this.state.delais.filter((e) => e.selected)[0].title.localeCompare("express") == 0 ? 1 : 0
      })
      .then(responsePost => {
        if(responsePost.body.transactionResult && responsePost.body.transactionResult.next_url.length > 0){
          this.setState({loading: false})
          window.location.href=responsePost.body.transactionResult.next_url;
        }
        else {
          setTimeout(() => {window.location.href="/order/confirmed/"+responsePost.body.orderId; this.setState({loading: false})}, 1000);
        }
        //clearBasket();
        //window.location.href="/order/confirmed/"+responsePost.body.orderId;
        //navigate("/order/confirmed", { state: { orderId: responsePost.body.orderId } }) 
      })
      .catch(error => {
        this.setState({loading: false})
      })
  }

  componentDidMount() {
    const loadingStringsInterval = setInterval(() => {
      if(this.stringManager.isLoaded()){
        this.setState({appIsReady: true})
        clearInterval(loadingStringsInterval);
        
        leadToOrdersClosed(this.stringManager.getVariable("PRESSANDCO_OPEN"));

        //this.fastDeliveryPrice = this.stringManager.getVariable("FASTDELIVERY_PRICE").value;
      }
    }, 50)


    mustBeLoggedIn();
    if(getStoredBasket().length == 0){
      window.location.href="/"
    }
    let minDelay = Math.max(...Object.values(getStoredBasket()).map((e) => e.product.minDelay));
    this.setState({
      loading: true,
      minDelay: minDelay,
      delais: [
      {
        title: 'normal',
        selected: false,
        disabled: minDelay > 2,
        gap: 2
      },
      {
        title: 'express',
        selected: false,
        disabled: minDelay > 1,
        gap: 1
      },
      {
        title: 'allonge',
        selected: true,
        disabled: false,
        gap: 3
      }]})




    apiFetcherLoggedIn("/users/"+this.account.id+"/mainAddresses", "GET")
    .then(responseMainAddresses => {
      this.setState({
        loading: false,
        pickupAddress: responseMainAddresses.body.deliveryAddress,
        billingAddress: responseMainAddresses.body.billingAddress
      })
    })
    .catch(error => {
      this.setState({loading: false})
    })

    this._initPaymentMethods();
  }

  render() { 
    let orderTTCPrice = roundPrice(this.totalPriceTTC + this.state.deliveryInfos.decidedPrice);
    return !this.state.appIsReady ? <SplashScreen /> :
    <Template loading={this.state.loading} stringManager={this.stringManager} pageTitle="Résumé de la commande">

      <PageSection title="Commander" className="order-container" centerClassName="order-center">
        <HorizontalGroup className="order-subcontainer">
          <main>
            <VerticalGroup>
              <h3>Adresse de livraison</h3>
              {
                this.state.pickupAddress ?
                  <Address 
                    editAction={() => this.openAddressListModal((addr) => {this.setState({pickupAddress: addr, billingAddress: addr})})} 
                    title={this.state.pickupAddress.wording}
                    content={this.state.pickupAddress.adress1+" "+this.state.pickupAddress.postalCode+" "+this.state.pickupAddress.city}
                    subcontent1={this.state.pickupAddress.adress2}
                    subcontent2={this.state.pickupAddress.adress3} />
                :
                  <Address
                    title="Ajouter une adresse"
                    content="Cliquez ici pour ajouter une nouvelle adresse."
                    addAction={() => this.openAddressListModal((addr) => {this.setState({pickupAddress: addr, billingAddress: addr})})} 
                  />
              }
              <h3>Adresse de facturation</h3>
              {
                this.state.billingAddress ?
                  <Address 
                    editAction={() => this.openAddressListModal((addr) => this.setState({billingAddress: addr}))} 
                    title={this.state.billingAddress.wording}
                    content={this.state.billingAddress.adress1+" "+this.state.billingAddress.postalCode+" "+this.state.billingAddress.city}
                    subcontent1={this.state.billingAddress.adress2}
                    subcontent2={this.state.billingAddress.adress3} />
                :
                  <Address
                    title="Ajouter une adresse"
                    content="Cliquez ici pour ajouter une nouvelle adresse."
                    addAction={() => this.openAddressListModal((addr) => this.setState({billingAddress: addr}))}
                  />
              }

              <h3>Option de livraison</h3>
              <fieldset>
                <RadioButton action={() => this.setState({delais: this.state.delais.map(e => {e.selected=e.title.localeCompare("normal") == 0; return e;})})} label="Livraison normale (48h)" selected={this.state.delais[0].selected} disabled={this.state.delais[0].disabled} _alertMinDelay={() => this._alertMinDelay(this.state.minDelay)} />
                {/*<RadioButton action={() => this.setState({delais: this.state.delais.map(e => {e.selected=e.title.localeCompare("express") == 0; return e})})} label={"Livraison express (24h) + "+roundPrice(this.fastDeliveryPrice)+"€ HT"} selected={this.state.delais[1].selected} disabled={this.state.delais[1].disabled} />*/}
                <RadioButton action={() => this.setState({delais: this.state.delais.map(e => {e.selected=e.title.localeCompare("allonge") == 0; return e})})} label="Livraison allongée (>48h)" selected={this.state.delais[2].selected} disabled={this.state.delais[2].disabled} _alertMinDelay={() => this._alertMinDelay(this.state.minDelay)} />
              </fieldset>

              <h3>Créneau de prise en charge</h3>
              {
                this.state.pickupSlot ?
                  <TimeSlot title="Prise en charge" content={`${formatDateToDaySentence(this.state.pickupSlot)} à ${formatDateToHoursAndMinutes(this.state.pickupSlot)}`} editAction={() => this.openSlotsListModal("pickup", (slot) => {this.setState({pickupSlot: slot, deliverySlot: null})})} />
                :
                  <TimeSlot title="Prise en charge" content="Cliquez ici pour choisir votre créneau" editAction={() => this.openSlotsListModal("pickup", (slot) => {this.setState({pickupSlot: slot, deliverySlot: null})})} />
              }

              <h3>Créneau de retour</h3>
              {
                this.state.pickupSlot ?
                  this.state.deliverySlot ?
                    <TimeSlot title="Retour de la commande" content={`${formatDateToDaySentence(this.state.deliverySlot)} à ${formatDateToHoursAndMinutes(this.state.deliverySlot)}`} editAction={() => this.openSlotsListModal("delivery", (slot) => this.setState({deliverySlot: slot}))} />
                  :
                    <TimeSlot title="Retour de la commande" content="Cliquez ici pour choisir votre créneau" editAction={() => this.openSlotsListModal("delivery", (slot) => this.setState({deliverySlot: slot}))} />
                :
                <TimeSlot title="Retour de la commande" content="Veuillez d'abord choisir un créneau de prise en charge" editAction={() => {
                  toast.error("Veuillez d'abord choisir un créneau de prise en charge.", {
                    position: "bottom-center"
                  });}} />
              }

              <h3>Moyen de paiement</h3>
              {
                this.state.paymentMethod ?
                  <CreditCard 
                    editAction={() => this.openCardsListModal()} 
                    title={this.state.paymentMethod.billing_details.name}
                    content={"xxxx xxxx xxxx "+this.state.paymentMethod.card.last4}
                    subcontent={this.state.paymentMethod.card.exp_month+"/"+this.state.paymentMethod.card.exp_year} />
                :
                  <CreditCard 
                    editAction={() => this.openCardsListModal()} 
                    title="Moyen de paiement"
                    content="Cliquez ici pour choisir votre moyen de paiement." />
              }

              <h3>Un commentaire ? (optionnel)</h3>
              <InputText large={true} label="Commentaire" val={this.state.comment} onChange={(e) => {this.setState({comment: e.target.value})}} />

              {
                this.state.pickupAddress && this.state.billingAddress && this.state.pickupSlot && this.state.deliverySlot && this.state.paymentMethod ?
                  <SimpleTextButton onClick={() => this._confirmOrder()}>Valider le paiement</SimpleTextButton>
                :
                  <SimpleTextButton 
                    className="simpletextbutton-container-dark-gray"
                    onClick={() => {
                      toast.error("Veuillez remplir tous les champs.", {
                        position: "bottom-center"
                      });
                    }}>Valider le paiement</SimpleTextButton>
              }
            </VerticalGroup>
          </main>
          <aside>
            <VerticalGroup>
              <div className="order-container-basket-sticky-container">
                <h3>Panier</h3>
                {this.basket.length > 0 ?
                  (this.basket.map((item, index) => (
                      <article>
                        <p className="order-container-basket-article-name">{item.product.product}</p>
                        <p className="order-container-basket-article-quantity">{item.count}x</p>
                        <p className="order-container-basket-article-quantity">{roundPrice(item.count*item.product.price)}€</p>
                      </article>
                    )))
                  : 
                  <span>Votre panier est vide.</span>
                }
                {/*
                  this.state.delais.filter(e => e.selected)[0].title.localeCompare("express") === 0 ?
                    <article>
                      <p className="order-container-basket-article-name">Livraison express</p>
                      <p className="order-container-basket-article-quantity">1x</p>
                      <p className="order-container-basket-article-quantity">{roundPrice(this.fastDeliveryPrice)}€</p>
                    </article>
                  :
                    <article>
                      <p className="order-container-basket-article-name">Livraison gratuite</p>
                      <p className="order-container-basket-article-quantity">1x</p>
                      <p className="order-container-basket-article-quantity">0€</p>
                    </article>
                */}
                {
                  this.state.deliveryInfos && this.state.deliveryInfos.decidedPrice > 0 ?
                    <article>
                      <p className="order-container-basket-article-name">Livraison payante (gratuite à partir de {roundPrice(this.state.deliveryInfos.min_for_free)}€ TTC pour une livraison en {(this.state.deliveryInfos.type) ? "vélo" : "voiture"})</p>
                      <p className="order-container-basket-article-quantity">1x</p>
                      <p className="order-container-basket-article-quantity">{roundPrice(this.state.deliveryInfos.decidedPrice)}€</p>
                    </article>
                  :
                    <article>
                      <p className="order-container-basket-article-name">Livraison gratuite (gratuite à partir de {roundPrice(this.state.deliveryInfos.min_for_free)}€ TTC pour une livraison en {(this.state.deliveryInfos.type) ? "vélo" : "voiture"})</p>
                      <p className="order-container-basket-article-quantity">1x</p>
                      <p className="order-container-basket-article-quantity">0€</p>
                    </article>
                }
                <p className="order-container-basket-price"><strong>TOTAL</strong> : {roundPrice(orderTTCPrice)}€ TTC ({roundPrice(orderTTCPrice/1.2)}€ HT)</p>
                <p className="order-container-basket-price"><strong>SOLDE FIDÉLITÉ</strong> : {roundPrice(this.state.subscriptionRemainingMoney)}€</p>
                <p className="order-container-basket-price"><strong>RESTE À PAYER</strong> : {roundPrice(orderTTCPrice - Math.min(orderTTCPrice, this.state.subscriptionRemainingMoney))}€ TTC</p>
                <SimpleTextButton className="simpletextbutton-container-blue" onClick={() => window.location.href="/order"}>Retourner à la commande</SimpleTextButton>
              </div>
            </VerticalGroup>
          </aside>
        </HorizontalGroup>




        
        <Modal
          isOpen={this.state.modalLC_open}
          onRequestClose={() => this.setState({modalLC_open: false})}
          style={this.modalStyle}
          contentLabel="Modal"
        >
          <h3>Choisissez une carte</h3>
          <hr />
          <div className="modalContent">
            <div className="center">
              <Address
              title="Ajouter une carte"
              content="Cliquez ici pour ajouter une nouvelle carte."
              addAction={() => this.openCardsAddModal()}
             />
              { this.state.modalLC_cards.length > 0 ?
                (this.state.modalLC_cards.map((item, index) => (
                  <CreditCard 
                    key={item.id}
                    viewAction={() => {this.closeAllModals(); this.setState({paymentMethod: item})}} 
                    title={item.billing_details.name}
                    content={"xxxx xxxx xxxx "+item.card.last4}
                    subcontent={item.card.exp_month+"/"+item.card.exp_year} />
                )))
                : 
                <span>Aucune carte enregistrée.</span>
              }
            </div>
          </div>
        </Modal>

        
        <Modal
          isOpen={this.state.modalLA_open}
          onRequestClose={() => this.setState({modalLA_open: false})}
          style={this.modalStyle}
          contentLabel="Modal"
        >
          <h3>Choisissez une addresse</h3>
          <hr />
          <div className="modalContent">
            <div className="center">
              <Address
              title="Ajouter une adresse"
              content="Cliquez ici pour ajouter une nouvelle adresse."
              addAction={() => this.openAddressAddModal()}
            />
              { this.state.modalLA_addresses.length > 0 ?
                (this.state.modalLA_addresses.map((item, index) => (
                  <Address 
                    key={index}
                    viewAction={() => {this.modalLA_callback(item); this.closeAllModals()}} 
                    title={item.wording}
                    content={item.adress1+" "+item.postalCode+" "+item.city}
                    subcontent1={item.adress2}
                    subcontent2={item.adress3} />
                )))
                : 
                <span>Aucune adresse enregistrée.</span>
              }
            </div>
          </div>
        </Modal>
        
        <Modal
          isOpen={this.state.modalAA_open}
          onRequestClose={() => this.setState({modalAA_open: false})}
          style={this.modalStyle}
          contentLabel="Modal"
        >
          <h3>Ajouter une addresse</h3>
          <hr />
          <div className="modalContent">
            <div className="center">
              <InputText label="Adresse de livraison" val={this.state.modalAA_address} onChangeAddress={(e) => this.setState({modalAA_address: e})} type="address" />
              <InputText label="Complément d'adresse 1" val={this.state.modalAA_address2} onChange={(e) => this.setState({modalAA_address2: e.target.value})} />
              <InputText label="Complément d'adresse 2" val={this.state.modalAA_address3} onChange={(e) => this.setState({modalAA_address3: e.target.value})} />
              <InputText label="Nom de l'adresse de livraison" val={this.state.modalAA_wording} onChange={(e) => this.setState({modalAA_wording: e.target.value})} />
            
              <SimpleTextButton 
                onClick={() => this.addAddress(() => {
                  this.closeAllModals(); 

                  toast.success("Adresse ajoutée.", {
                    position: "bottom-center"
                  });
                })}>Ajouter</SimpleTextButton>
            </div>
          </div>
        </Modal>
        
        <Modal
          isOpen={this.state.modalLS_open}
          onRequestClose={() => this.setState({modalLS_open: false})}
          style={this.modalStyle}
          contentLabel="Modal"
        >
          <h3>Choisissez votre créneau</h3>
          <hr />
          <p class="minTreatment">Temps de traitement minimum : {this.state.minDelay} jours</p>
          {
            this.state.pickupSlot && (this.state.pickupSlot.getDay() == 0 || this.state.pickupSlot.getDay() == 6) ?
              <p class="minTreatment">+24h pour une prise en charge le week-end</p>
              :
              <></>
          }
          <hr />
          <div className="modalContent modalSlots">
            <div className="center">
              <HorizontalGroup className="horizontal-spaced keep-orientation">
                <VerticalGroup className="flex1">
                  <p className="subtitle">Jour</p>
                  { this.state.modalLS_days.length > 0 ?
                    (this.state.modalLS_days.map((item, index) => (
                      <SimpleTextButton
                        onClick={() => {this.setState({modalLS_selectedDay: item}); this._loadDaySlots(item)}}
                        className={areSameDay(this.state.modalLS_selectedDay, item) || (!this.state.modalLS_selectedDay && areSameDay(item, this.state.modalLS_mode.localeCompare("pickup") == 0 ? this.state.pickupSlot : this.state.deliverySlot)) ? "simpletextbutton-container-dark-blue" : ""}>{formatDateToDaySentence(item)}</SimpleTextButton>
                    )))
                    : 
                    <p className="content">Aucun jour disponible.</p>
                  }
                </VerticalGroup>
                <VerticalGroup className="flex1">
                  <p className="subtitle">Créneau</p>
                  { this.state.modalLS_slots_current.length > 0 ?
                    (this.state.modalLS_slots_current.map((item, index) => (
                      <SimpleTextButton
                        onClick={() => {this.closeAllModals(); this.modalLS_callback(item.start_time);}}>{formatDateToHoursAndMinutes(item.start_time)}</SimpleTextButton>
                    )))
                    : 
                    <p className="content">Aucun créneau disponible pour cette journée.</p>
                  }
                </VerticalGroup>
              </HorizontalGroup>
            </div>
          </div>
        </Modal>

      </PageSection>
    
    </Template>
  }

}