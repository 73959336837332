import Template from "../components/template";
import PageSection from "../components/pageSection";
import './../resources/css/global.css';
import './../resources/css/colors.css';
import './../resources/css/notFound.css';
import SimpleTextButton from "../components/simpleTextButton";
import StringsManager from "../utils/stringsManager";
import SplashScreen from "../components/splashScreen";
import React, {useEffect} from "react";

export default class notFound extends React.Component {

  constructor(props) {
    super(props);
    this.stringManager = StringsManager.getInstance();
    this.state = {
      appIsReady: false
    }
  }

  componentDidMount() {
    const loadingStringsInterval = setInterval(() => {
      if(this.stringManager.isLoaded()){
        this.setState({appIsReady: true})
        clearInterval(loadingStringsInterval);
      }
    }, 50)
  }

  render() { 
    return !this.state.appIsReady ? <SplashScreen /> :
      <Template stringManager={this.stringManager} pageTitle="Page introuvable">

        <PageSection title="Page non trouvée" className="notFound-container" centerClassName="notFound-center">
          
          <SimpleTextButton onClick={() => window.location="/"}>Retour à l'accueil</SimpleTextButton>
          
        </PageSection>
      
      </Template>
    }
}