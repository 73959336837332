import Template from "../components/template";
import PageSection from "../components/pageSection";
import './../resources/css/global.css';
import './../resources/css/colors.css';
import './../resources/css/notFound.css';
import SimpleTextButton from "../components/simpleTextButton";
import StringsManager from "../utils/stringsManager";
import SplashScreen from "../components/splashScreen";
import React, {useEffect} from "react";
import loading from './../resources/img/loading.gif';
import './../resources/css/waitingPayment.css';
import { apiFetcher } from "../utils/fetcher";
import {WEBSITE_URL, ADMIN_WEBSITE_URL} from "./../utils/config";

export default class WaitingPayment extends React.Component {

  constructor(props) {
    super(props);
    
  }

  componentDidMount() {
    const slug = window.location.pathname.replace("/waitingPayment/", "");
    if(slug){
      const slugInfos = slug.split("/");
      if(slugInfos.length === 2){
        const type = slugInfos[0];
        const id = slugInfos[1];

        if(!["order", "subscription", "orderSupplement"].includes(type)){
          window.location.href = "/";
        }

        if(type === "subscription"){
          let interval = setInterval(() => {
            apiFetcher("/subscriptionTransactions/isPaid/"+id).then(resp => {
              console.log(resp);
              if(resp.body.isPaid){
                clearInterval(interval);
                  window.location.href = "/profile";
              }
            })
          }, 1000);
        }
        else if(type === "order"){
          let interval = setInterval(() => {
            apiFetcher("/orderTransactions/isPaid/"+id).then(resp => {
              console.log(resp);
              if(resp.body.isPaid){
                clearInterval(interval);
                  window.location.href = "/order/confirmed/"+resp.body.orderId;
              }
            })
          }, 1000);
        }
        else if(type === "orderSupplement"){
          let interval = setInterval(() => {
            apiFetcher("/orderTransactions/isPaid/"+id).then(resp => {
              console.log(resp);
              if(resp.body.isPaid){
                clearInterval(interval);
                window.location.href = ADMIN_WEBSITE_URL+"/#/orders/"+resp.body.orderId;
              }
            })
          }, 1000);
        }
      }
    }
  }

  render() { 
    return (
      <div className="waitingpayment-container">
        <h1>Vérification du paiement en cours</h1>

        <img src={loading} alt="loading" style={{width: 100, height: 100}} />

        <h2>Veuillez patienter quelques instants ...</h2>
      </div>
    )
  }
}