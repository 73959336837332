import Template from "../components/template";
import PageSection from "../components/pageSection";
import InputText from "../components/inputText";
import './../resources/css/global.css';
import './../resources/css/colors.css';
import './../resources/css/passwordLost.css';
import SimpleTextButton from "../components/simpleTextButton";

import React, { useState } from 'react';
import toast from 'react-hot-toast';


import { setStoredAccount, login, askResetPassword } from "../utils/methods";
import StringsManager from "../utils/stringsManager";
import SplashScreen from "../components/splashScreen";


export default class PasswordLost extends React.Component {

  constructor(props) {
    super(props);
    this.liteMode = props.liteMode;
    this.stringManager = StringsManager.getInstance();
    this.state = {
      appIsReady: false,
      loading: false,
      email: "",
    }
  }

  componentDidMount() {
    const loadingStringsInterval = setInterval(() => {
      if(this.stringManager.isLoaded()){
        this.setState({appIsReady: true})
        clearInterval(loadingStringsInterval);
      }
    }, 50)
  }

  _passwordLost = () => {
    this.setState({loading: true});

    askResetPassword(this.state.email)
    .then(res => {
      this.setState({loading: false, email: ""});

      /*toast.success(res.body.message, {
        position: "bottom-center"
      });*/
      toast.success("La méthode de changement de votre mot de passe vous a été envoyé par mail.", {
        position: "bottom-center"
      });
      if(!this.liteMode){
        setTimeout(() => window.location.href = "/", 2000);
      }
      
    })
    .catch(err => this.setState({loading: false}))
  }

  render() { 
    return !this.state.appIsReady ? <SplashScreen /> : (
      <Template loading={this.state.loading} stringManager={this.stringManager} pageTitle="Mot de passe perdu" liteMode={this.liteMode}>

        <PageSection title="Mot de passe perdu" className="passwordLost-container" centerClassName="passwordLost-center">
          <InputText label="Email" onChange={(e) => this.setState({email: e.target.value})} val={this.state.email} />

          <SimpleTextButton onClick={() => this._passwordLost()}>Réinitialiser le mot de passe</SimpleTextButton>
        </PageSection>
      
      </Template>
    )}
  }