import React from 'react'
import './../resources/css/colors.css';
import './../resources/css/radioButton.css';
import HorizontalGroup from './horizontalGroup';

function RadioButton(props) {
    let action = props.action;
    let _alertMinDelay = props._alertMinDelay;
    return (
        <HorizontalGroup onClick={() => (props.disabled) ? _alertMinDelay() : action()} className={`radioButton-container keep-orientation ${props.selected ? "radioButton-container-selected" : ""}  ${props.disabled ? "radioButton-container-disabled" : ""}`}>
            <div className="radioButton-container-checkbox"><div></div></div>
            <p>{props.label}</p>
        </HorizontalGroup>
    )
}

export default RadioButton;