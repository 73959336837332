import Template from "../components/template";
import PageSection from "../components/pageSection";
import './../resources/css/global.css';
import './../resources/css/colors.css';
import './../resources/css/dynamicPage.css';
import SimpleTextButton from "../components/simpleTextButton";
import StringsManager from "../utils/stringsManager";
import SplashScreen from "../components/splashScreen";
import React from "react";
import { apiFetcher } from "../utils/fetcher";
import HorizontalGroup from "../components/horizontalGroup";

export default class DynamicPage extends React.Component {


  constructor(props) {
    super(props);
    this.stringManager = StringsManager.getInstance();
    this.liteMode = props.liteMode;
    this.state = {
      appIsReady: false
    }
  }

  componentDidMount() {
    const loadingStringsInterval = setInterval(() => {
      if(this.stringManager.isLoaded()){
        this.setState({appIsReady: true})
        clearInterval(loadingStringsInterval);
      }
    }, 50)

    let slug = window.location.pathname.replace("/lite/page/", "").replace("/page/", "");
    if(slug){
      this._loadContent(slug);
    }
  }

  _loadContent = (slug) => {
    this.setState({loading: true})
    apiFetcher("/websiteContents/slug/"+slug, "GET")
      .then(websiteContent => {
        if(websiteContent && websiteContent.body && Object.keys(websiteContent.body).length > 0 && websiteContent.status !== 404){
          this.setState({loading: false, websiteContent: websiteContent.body})
          document.title="PressAndCo | "+websiteContent.body.title;
        }
        else {
          this.setState({loading: false});
          window.location.href="/";
        }
      })
      .catch(err => {
        this.setState({loading: false});
        window.location.href="/";
      })
  }

    render() { 
      return !this.state.appIsReady ? <SplashScreen /> : (
      <Template stringManager={this.stringManager} liteMode={this.liteMode}>

        <PageSection className="dynamicPage-container" centerClassName="dynamicPage-center">
          <HorizontalGroup className="dynamicPage-container-flex">
            <article>
              <div dangerouslySetInnerHTML={{__html: this.state.websiteContent.content}} />
            </article>
            <aside>
              <div className="dynamicPage-contactInfos">
                <h5>Nous contacter</h5>

                <a href={"tel:+33"+this.stringManager.getMessage("GLOBAL_PRESSANDCO_PHONE").slice(1, this.stringManager.getMessage("GLOBAL_PRESSANDCO_PHONE").length).replace(new RegExp(" ", "g"), "")}>{this.stringManager.getMessage("GLOBAL_PRESSANDCO_PHONE")}</a>
                <a href={"mailto:"+this.stringManager.getMessage("GLOBAL_PRESSANDCO_EMAIL")}>{this.stringManager.getMessage("GLOBAL_PRESSANDCO_EMAIL")}</a>

                <p>PressandCo</p>
                <p>{this.stringManager.getMessage("GLOBAL_PRESSANDCO_ADDRESS")}</p>
              </div>
            </aside>
          </HorizontalGroup>
          
        </PageSection>
      
      </Template>
    )}
}