import React from 'react'
import './../resources/css/verticalGroup.css';

function VerticalGroup(props) {
    return (
        <div onClick={props.onClick ? () => props.onClick() : () => {}} className={`verticalgroup-container ${props.className ? props.className : ""}`}>
            {props.children}
        </div>
    )
}

export default VerticalGroup;