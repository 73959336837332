import React from 'react'
import './../resources/css/colors.css';
import './../resources/css/productSection.css';
import { IoIosArrowUp, IoIosArrowDown } from 'react-icons/io';
import { useState, useEffect } from 'react';

function ProductSection(props) {
    
    const [isArrowUp, setArrowUp] = useState(false);

    return (
        <section className="ordersection-container">
            <div className="ordersection-container-title" onClick={() => setArrowUp(!isArrowUp)}>
                {props.title}
                <IoIosArrowUp className={`ordersection-container-arrow ${isArrowUp ? "" : "ordersection-container-arrow-hidden"}`} size="30px" />
                <IoIosArrowDown className={`ordersection-container-arrow ${isArrowUp ? "ordersection-container-arrow-hidden" : ""}`} size="30px" />
            </div>
            <div className={`ordersection-container-products ${isArrowUp ? "" : "ordersection-container-products-hidden"}`}>
                {props.children}
            </div>
        </section>
    )
}

export default ProductSection;