import Template from "../components/template";
import PageSection from "../components/pageSection";
import InputText from "../components/inputText";
import './../resources/css/global.css';
import './../resources/css/colors.css';
import './../resources/css/contact.css';
import SimpleTextButton from "../components/simpleTextButton";
import StringsManager from "../utils/stringsManager";
import SplashScreen from "../components/splashScreen";
import React, {useEffect} from "react";
import HorizontalGroup from "../components/horizontalGroup";
import IconLineText from "../components/iconLineText";
import { HiOutlineMail } from 'react-icons/hi';
import { AiOutlinePhone } from 'react-icons/ai';
import { AiOutlineHome } from 'react-icons/ai';
import VerticalGroup from "../components/verticalGroup";
import toast from 'react-hot-toast';
import { getUTCTimestamp, isEmailValid } from "../utils/methods";
import { apiFetcher } from "../utils/fetcher";

export default class Contact extends React.Component {

  constructor(props) {
    super(props);
    this.stringManager = StringsManager.getInstance();
    this.liteMode = props.liteMode;
    this.state = {
      appIsReady: false,
      contactEmail: "",
      contactFirstName: "",
      contactLastName: "",
      contactSubject: "",
      contactMessage: "",
    }
  }

  componentDidMount() {
    const loadingStringsInterval = setInterval(() => {
      if(this.stringManager.isLoaded()){
        this.setState({appIsReady: true})
        clearInterval(loadingStringsInterval);
      }
    }, 50)
  }

  _sendMail = () => {
    let currentMoment = getUTCTimestamp();
    if(localStorage.getItem("lastContactMailSent") != null){
      let lastSentMailTime = parseInt(localStorage.getItem("lastContactMailSent"));
      if((currentMoment - lastSentMailTime) < (6*60*60)){
        toast.error("Vous ne pouvez envoyer un mail que toutes les 6h.", {
          position: "bottom-center"
        });
        return;
      }
    }

    let errors = [];
    if(!isEmailValid(this.state.contactEmail)){
      errors.push("Le format de l'adresse mail est invalide.");
    }
    if(this.state.contactFirstName.length == 0 ||
      this.state.contactLastName.length == 0 ||
      this.state.contactEmail.length == 0 ||
      this.state.contactSubject.length == 0 ||
      this.state.contactMessage.length == 0){
      errors.push("Veuillez remplir tous les champs");
    }

    if(errors.length > 0){
      for(let error of errors){
        toast.error(error, {
          position: "bottom-center"
        });
      }
      return;
    }

    localStorage.setItem("lastContactMailSent", currentMoment);
    apiFetcher("/utils/sendContactMail", "POST", {
      contactFirstName: this.state.contactFirstName,
      contactEmail: this.state.contactEmail,
      contactLastName: this.state.contactLastName,
      contactMessage: this.state.contactMessage,
      contactSubject: this.state.contactSubject,
    })

    this.setState({
      contactEmail: "",
      contactFirstName: "",
      contactLastName: "",
      contactMessage: "",
      contactSubject: ""
    })

    toast.success("Votre message a bien été envoyé, nous reviendrons vers vous au plus vite.", {
      position: "bottom-center"
    });
  }

  render() { 
    return !this.state.appIsReady ? <SplashScreen /> :
      <Template stringManager={this.stringManager} pageTitle="Contact" liteMode={this.liteMode}>

        <PageSection title={this.stringManager.getMessage("CONTACT_TITLE")} description={this.stringManager.getMessage("CONTACT_DESCRIPTION")} className="contact-container" centerClassName="contact-center">

          <HorizontalGroup>
            <IconLineText 
              leftMode="true" 
              icon={<HiOutlineMail size="80px" />} 
              title={"Email"}
              description={this.stringManager.getMessage("GLOBAL_PRESSANDCO_EMAIL")}
            />
            <IconLineText 
              icon={<AiOutlinePhone size="80px" />} 
              title={"Téléphone"}
              description={this.stringManager.getMessage("GLOBAL_PRESSANDCO_PHONE")}
              />
            <IconLineText 
              rightMode="true" 
              icon={<AiOutlineHome size="80px" />} 
              title={"Adresse"}
              description={this.stringManager.getMessage("GLOBAL_PRESSANDCO_ADDRESS")}
              />
          </HorizontalGroup>

          <VerticalGroup className="form-container">
            <HorizontalGroup>
              <InputText label="Prénom" val={this.state.contactFirstName} onChange={(e) => this.setState({contactFirstName: e.target.value})} />
              <InputText label="Nom" val={this.state.contactLastName} onChange={(e) => this.setState({contactLastName: e.target.value})} />
            </HorizontalGroup>
            <HorizontalGroup>
              <InputText label="Votre email" val={this.state.contactEmail} onChange={(e) => this.setState({contactEmail: e.target.value})} />
              <InputText label="Sujet du contact" val={this.state.contactSubject} onChange={(e) => this.setState({contactSubject: e.target.value})} />
            </HorizontalGroup>
            <InputText label="Message" large={true} val={this.state.contactMessage} onChange={(e) => this.setState({contactMessage: e.target.value})} />
            <SimpleTextButton onClick={this._sendMail} className="sendButton">Envoyer</SimpleTextButton>
          </VerticalGroup>
          
        </PageSection>
      
      </Template>
    }
}