import Template from "../components/template";
import PageSection from "../components/pageSection";
import './../resources/css/global.css';
import './../resources/css/colors.css';
import './../resources/css/subscriptions.css';
import Address from "../components/address";
import { getStoredAccount, mustBeLoggedIn, roundPrice } from "../utils/methods";
import { useEffect, useState } from "react";
import { apiFetcherLoggedIn } from "../utils/fetcher";

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import toast from "react-hot-toast";
import Subscription from "../components/subscription";
import HorizontalGroup from "../components/horizontalGroup";
import SimpleTextButton from "../components/simpleTextButton";
import { Link, useNavigate } from "react-router-dom";
import StringsManager from "../utils/stringsManager";
import SplashScreen from "../components/splashScreen";
import React from "react";
import CreditCard from "../components/creditCard";
import Modal from 'react-modal';
import { WEBSITE_URL } from "../utils/config";

export default class Subscriptions extends React.Component {

  constructor(props) {
    super(props);
    this.stringManager = StringsManager.getInstance();
    this.state = {
      appIsReady: false,
      loading: false,
      subscriptions: [],
      subscriptionChosen: null,
      creditCardChosen: null,
      modalLC_cards: [],
      modalLC_open: false,
      paymentMethods: []
    }
    this.account = getStoredAccount();



    this.modalStyle = {
      overlay: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 9999
      },
      content: {
        position: "relative",
        width: 600,
        marginBottom: "70px"
      }
    };
  }

  _loadSubscriptions = () => {
    this.setState({
      loading: true,
      subscriptions: []
    })

    apiFetcherLoggedIn("/subscriptions", "GET")
    .then(subscriptions => {
      this.setState({
        loading: false,
        subscriptions: subscriptions.body
      })
    })
    .catch(err => this.setState({
      loading: false,
    }))
  }

  componentDidMount() {
    mustBeLoggedIn();
    this._loadSubscriptions();
    this._initPaymentMethods();

    const loadingStringsInterval = setInterval(() => {
      if(this.stringManager.isLoaded()){
        this.setState({appIsReady: true})
        clearInterval(loadingStringsInterval);
      }
    }, 50)
  }

  openCardsListModal = () => {
    this.closeAllModals();
    this.setState({
      loading: false,
      modalLC_cards: this.state.paymentMethods,
      modalLC_open: true
    })
  }

  _initPaymentMethods = () => {
    this.setState({loading: true})
    return apiFetcherLoggedIn("/orders/listPaymentMethods", "GET")
      .then(paymentMethods => {
        this.setState({loading: false, paymentMethods: paymentMethods.body.data}, () => this._initCreditCard());
      })
  }

  _initCreditCard = () => {
    if(this.state.paymentMethods.length > 0){
      let creditCardChosen = this.state.paymentMethods[0];
      this.setState({creditCardChosen: creditCardChosen});
    }
  }

  openCardsAddModal = () => {
    this.setState({loading: true})
    this.closeAllModals();
    apiFetcherLoggedIn("/orders/createCheckout", "POST", {
      successUrl: WEBSITE_URL+"/order/summary",
      cancelUrl: WEBSITE_URL+"/order/summary"
    })
      .then(response => {
        this.setState({loading: false})
        const paymentURL = response.body.url;
        window.location.href = paymentURL;
      })
  }

  closeAllModals = () => {
    this.setState({
      modalLC_open: false,
    })
  }

  _paySubscription = () => {
    this.setState({
      loading: true,
    })
    let account = getStoredAccount();
    apiFetcherLoggedIn("/users/"+account.id+"/subscribe/"+this.state.subscriptionChosen.id, "POST", {
      paymentMethodId: this.state.creditCardChosen.id
    })
    .then(response => {
      this.setState({creditCardChosen: null, subscriptionChosen: null})
      toast.success("Paiement en cours de vérification.", {
        position: "bottom-center"
      });
      if(response.body.next_url.length > 0){
        window.location.href=response.body.next_url;
      }
      else {
        setTimeout(() => {window.location.href="/profile"; this.setState({loading: false})}, 1000);
      }
    })
    .catch(error => {
      this.setState({loading: false})
      toast.error("Une erreur s'est produite.", {
        position: "bottom-center"
      });
    })
  }

  render() { 
    return !this.state.appIsReady ? <SplashScreen /> :
    <Template loading={this.state.loading} stringManager={this.stringManager} pageTitle="Fidélité">

      <PageSection title="Fidélité" className="subscriptions-container" centerClassName="subscriptions-center">
          
          <HorizontalGroup className="subscriptions-container-horizontal horizontalgroup-containe-mobile-center">
            <Link to="/profile"><SimpleTextButton>Retour au profil</SimpleTextButton></Link>
          </HorizontalGroup>

          <hr />

          <h4>Choisissez votre offre de fidélité</h4>
          <p class="subscriptions-container-subtitle">Cliquez sur l'offre que vous souhaitez prendre</p>

          { this.state.subscriptions.length > 0 ?
            (this.state.subscriptions.map((item, index) => (
              <Subscription 
                title={"Recevez "+roundPrice(item.amountToObtain)+"€"}
                content={"En payant seulement "+roundPrice(item.price)+"€"}
                selectAction={() => {this.setState({subscriptionChosen: item}); window.location.hash = "#subscription-recap";}}
              />
            )))
            : 
            <span>Aucune offre de fidélité disponible.</span>
          }

          <hr  id="subscription-recap" />

          <h4>Récapitulatif</h4>
          <p class="subscriptions-container-subtitle">Retrouvez l'offre choisie et sélectionnez votre moyen de paiement.</p>

          {
            this.state.subscriptionChosen ?
              <Subscription 
                title={"Recevez "+roundPrice(this.state.subscriptionChosen.amountToObtain)+"€"}
                content={"En payant seulement "+roundPrice(this.state.subscriptionChosen.price)+"€"}
                deleteAction={() => this.setState({subscriptionChosen: null})}
              />
            :
              <Subscription 
                title={"Aucune offre de fidélité choisie"}
                content={"Choisissez une offre de fidélité ci-dessus"}
                deleteAction={() => {}}
              />
          }

          {
            this.state.creditCardChosen ?
              <CreditCard 
                editAction={() => this.openCardsListModal()} 
                title={this.state.creditCardChosen.billing_details.name}
                content={"xxxx xxxx xxxx "+this.state.creditCardChosen.card.last4}
                subcontent={this.state.creditCardChosen.card.exp_month+"/"+this.state.creditCardChosen.card.exp_year} />
            :
              <CreditCard 
                editAction={() => this.openCardsListModal()} 
                title="Moyen de paiement"
                content="Cliquez ici pour choisir votre moyen de paiement." />
          }

          {
            (this.state.subscriptionChosen && this.state.creditCardChosen) ?
              <SimpleTextButton onClick={this._paySubscription}>Procéder au paiement</SimpleTextButton>
            :
              <SimpleTextButton className="simpletextbutton-container-dark-gray">Procéder au paiement</SimpleTextButton>
          }




        <Modal
          isOpen={this.state.modalLC_open}
          onRequestClose={() => this.setState({modalLC_open: false})}
          style={this.modalStyle}
          contentLabel="Modal"
        >
          <h3>Choisissez une carte</h3>
          <hr />
          <div className="modalContent">
            <div className="center">
              <Address
              title="Ajouter une carte"
              content="Cliquez ici pour ajouter une nouvelle carte."
              addAction={() => this.openCardsAddModal()}
             />
              { this.state.modalLC_cards.length > 0 ?
                (this.state.modalLC_cards.map((item, index) => (
                  <CreditCard 
                    key={item.id}
                    viewAction={() => {this.closeAllModals(); this.setState({creditCardChosen: item})}} 
                    title={item.billing_details.name}
                    content={"xxxx xxxx xxxx "+item.card.last4}
                    subcontent={item.card.exp_month+"/"+item.card.exp_year} />
                )))
                : 
                <span>Aucune carte enregistrée.</span>
              }
            </div>
          </div>
        </Modal>

      </PageSection>
    
    </Template>
  }
}