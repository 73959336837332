import React from 'react'
import './../resources/css/horizontalGroup.css';

function HorizontalGroup(props) {
    return (
        <div onClick={props.onClick ? () => props.onClick() : () => {}} className={`horizontalgroup-container ${props.className ? props.className : ""}`}>
            {props.children}
        </div>
    )
}

export default HorizontalGroup;