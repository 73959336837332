import ReactDOM from "react-dom/client";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import Home from "./routes/home";
import NotFound from "./routes/notFound";
import Login from "./routes/login";
import './index.css';
import SignUp from "./routes/signup";
import DynamicPage from "./routes/dynamicPage";
import Order from "./routes/order";
import Profile from "./routes/profile";
import ProfileAddresses from "./routes/profileAddresses";
import ProfilePaymentMethods from "./routes/profilePaymentMethods";
import ProfileOrders from "./routes/profileOrders";
import OrderSummary from "./routes/orderSummary";
import OrderConfirmed from "./routes/orderConfirmed";
import Contact from "./routes/contact";
import OrderClosed from "./routes/orderClosed";
import PasswordLost from "./routes/passwordLost";
import PasswordLostToken from "./routes/passwordLostToken";
import Subscriptions from "./routes/subscriptions";
import WaitingPayment from "./routes/waitingPayment"

const root = ReactDOM.createRoot(
  document.getElementById("root")
);
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/lite/page/:slug" element={<DynamicPage liteMode={true} />} />
      <Route path="/lite/contact" element={<Contact liteMode={true} />} />
      <Route path="/lite/passwordLost" element={<PasswordLost liteMode={true} />} />

      <Route path="/" element={<Home />} />
      <Route path="/login" element={<Login />} />
      <Route path="/passwordLost" element={<PasswordLost />} />
      <Route path="/passwordLost/:token" element={<PasswordLostToken />} />
      <Route path="/signup" element={<SignUp />} />
      <Route path="/page/:slug" element={<DynamicPage />} />
      <Route path="/order" element={<Order />} />
      <Route path="/order/summary" element={<OrderSummary />} />
      <Route path="/order/closed" element={<OrderClosed />} />
      <Route path="/order/confirmed/:id" element={<OrderConfirmed />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/profile/addresses" element={<ProfileAddresses />} />
      <Route path="/profile/paymentMethods" element={<ProfilePaymentMethods />} />
      <Route path="/profile/orders" element={<ProfileOrders />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/subscriptions" element={<Subscriptions />} />
      <Route path="/waitingPayment/:type/:id" element={<WaitingPayment />} />
      <Route path="*" element={<NotFound />} status={404} />
    </Routes>
  </BrowserRouter>
);