import React from 'react'
import './../resources/css/colors.css';
import './../resources/css/iconLineText.css';

function IconLineText(props) {
    return (
        <div className="iconlinetext-container">
            <div className="containerTop">
                {props.leftMode ? 
                    <div className="line"></div>
                : <hr className="line" />}
                
                {props.icon}

                {props.rightMode ? 
                    <div className="line"></div>
                : <hr className="line" />}
            </div>
            <div className="containerBottom">
                <h4 className="title">{props.title}</h4>
                <p className="description">{props.description}</p>
            </div>
        </div>
    )
}

export default IconLineText;