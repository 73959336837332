import Template from "../components/template";
import PageSection from "../components/pageSection";
import InputText from "../components/inputText";
import './../resources/css/global.css';
import './../resources/css/colors.css';
import './../resources/css/profile.css';
import SimpleTextButton from "../components/simpleTextButton";
import VerticalGroup from "../components/verticalGroup";
import HorizontalGroup from "../components/horizontalGroup";
import { Link, useNavigate } from "react-router-dom";
import React, { useState } from 'react';
import { getStoredAccount, isIndividualAccount, isLoggedIn, isPasswordValid, isPhoneNumberValid, isSiretValid, mustBeLoggedIn } from "../utils/methods";
import { apiFetcherLoggedIn } from "../utils/fetcher";
import toast from "react-hot-toast";
import StringsManager from "../utils/stringsManager";
import SplashScreen from "../components/splashScreen";

export default class Profile extends React.Component {

  constructor(props) {
    super(props);
    this.stringManager = StringsManager.getInstance();
    this.state = {
      appIsReady: false,
      loading: false,
      email: "",
      phone: "",
      password1: "",
      password2: "",
      firstName: "",
      lastName: "",
      companyName: "",
      siret: "",
      subscriptionRemainingMoney: 0,
      newsletter: false
    }
    this.account = getStoredAccount();
  }

  _loadProfile = () => {
    this.setState({
      loading: true
    })
    apiFetcherLoggedIn("/users/"+this.account.id, "GET").then(userInfos => {
      this.setState({
        email: userInfos.body.email,
        phone: userInfos.body.phone,
        subscriptionRemainingMoney: userInfos.body.subscriptionRemainingMoney,
        newsletter: userInfos.body.newsletter
      })

      if(isIndividualAccount(this.account)){
        apiFetcherLoggedIn("/users/individual/"+this.account.id, "GET").then(individualInfos => {
          this.setState({
            firstName: individualInfos.body.firstName,
            lastName: individualInfos.body.lastName,
            loading: false
          })
        })
      }
      else {
        apiFetcherLoggedIn("/users/business/"+this.account.id, "GET").then(businessInfos => {
          this.setState({
            companyName: businessInfos.body.name,
            siret: businessInfos.body.siret,
            loading: false
          })
        })
      }
    })
  }

  _updatePassword = () => {
    this.setState({
      loading: true
    })

    let errors = [];

    if(!isPasswordValid(this.state.password1)){
      errors.push('Format du mot de passe invalide. Il doit contenir au moins 8 caractères, une majuscule, une minuscule et un caractère numérique.');
    }
    else if(this.state.password1.localeCompare(this.state.password2) != 0){
      errors.push("Les deux mots de passe ne correspondent pas.")
    }

    if(errors.length > 0){
      this.setState({
        loading: false
      })
      for(let error of errors){
        toast.error(error, {
          position: "bottom-center"
        });
      }
    }
    else {
      apiFetcherLoggedIn("/users/"+this.account.id+"/password", "PUT", {
        password: this.state.password1
      }).then(() => {
        this.setState({
          loading: false
        });  
        toast.success("Mot de passe mis à jour.", {
          position: "bottom-center"
        });
      })
      .catch(error => 
        this.setState({
          loading: false
        }));  
    }
  }

  _updateAccount = () => {
    this.setState({
      loading: true
    })

    let errors = [];
    if(!isPhoneNumberValid(this.state.phone)){
      errors.push("Veuillez rentrer un numéro de téléphone valide.");
    }
    if(isIndividualAccount(this.account)){
      if(this.state.firstName.length == 0){
        errors.push("Veuillez indiquer un prénom.");
      }
      if(this.state.lastName.length == 0){
        errors.push("Veuillez indiquer un prénom.");
      }
    }
    else {
      if(this.state.companyName.length == 0){
        errors.push("Veuillez rentrer un nom d'entreprise.");
      }
      if(!isSiretValid(this.state.siret)){
        errors.push("Veuillez rentrer un numéro de siret valide.");
      }
    }

    if(errors.length > 0){
      this.setState({
        loading: false
      })
      for(let error of errors){
        toast.error(error, {
          position: "bottom-center"
        });
      }
    }
    else {
      apiFetcherLoggedIn("/users/"+this.account.id+"/emailAndPhone", "PUT", {
        email: this.state.email,
        phone: this.state.phone,
        newsletter: this.state.newsletter
      }).then(() =>  {
        if(isIndividualAccount(this.account)){
          apiFetcherLoggedIn("/users/individual/"+this.account.id+"/only", "PUT", {
            clientId: this.account.id,
            firstName: this.state.firstName,
            lastName: this.state.lastName,
          }).then(() =>  {
            this.setState({
              loading: false
            })
            toast.success("Informations mises à jour.", {
              position: "bottom-center"
            });
          })
          .catch(error => this.setState({
            loading: false
          }));      
        } 
        else {
          apiFetcherLoggedIn("/users/business/"+this.account.id+"/only", "PUT", {
            clientId: this.account.id,
            name: this.state.companyName,
            siret: this.state.siret,
          }).then(() =>  {
            this.setState({
              loading: false
            })
            toast.success("Informations mises à jour.", {
              position: "bottom-center"
            });
          })
          .catch(error => {
            this.setState({
              loading: false
            });
          });  
        }
      })
      .catch(error => {
        this.setState({
          loading: false
        });
      });  
    }
  }

  componentDidMount() {
    const loadingStringsInterval = setInterval(() => {
      if(this.stringManager.isLoaded()){
        this.setState({appIsReady: true})
        clearInterval(loadingStringsInterval);
      }
    }, 50)

    mustBeLoggedIn();
    this._loadProfile();
  }

  render() { 
    return !this.state.appIsReady ? <SplashScreen /> :
      <Template loading={this.state.loading} stringManager={this.stringManager} pageTitle="Profil">

        <PageSection title="Mon profil" className="profile-container" centerClassName="profile-center">
          <VerticalGroup className="profile-container-vertical">

            <HorizontalGroup className="profile-container-horizontal horizontalgroup-containe-mobile-center">
              <Link to="/profile/addresses"><SimpleTextButton>Mes adresses</SimpleTextButton></Link>
              <Link to="/profile/paymentMethods"><SimpleTextButton>Mes moyens de paiement</SimpleTextButton></Link>
              <Link to="/profile/orders"><SimpleTextButton>Mes commandes</SimpleTextButton></Link>
            </HorizontalGroup>

            <hr class="profile-hr-margin-bottom" />

            <VerticalGroup className="profile-solde-container">
              <h4 className="profile-solde-title"><strong>Solde disponible : </strong>{this.state.subscriptionRemainingMoney}€</h4>
              <Link to="/subscriptions"><SimpleTextButton>Voir la liste des offres de fidélité</SimpleTextButton></Link>
            </VerticalGroup>

            <hr class="profile-hr-margin-top" />

            <InputText disabled={true} label="Email" val={this.state.email} />
            <InputText label="Téléphone" val={this.state.phone} onChange={(e) => this.setState({phone: e.target.value})} />

            {
              isIndividualAccount(this.account) ?
                <>
                  <InputText label="Prénom" val={this.state.firstName} onChange={(e) => this.setState({firstName: e.target.value})} />
                  <InputText label="Nom" val={this.state.lastName} onChange={(e) => this.setState({lastName: e.target.value})} />
                </>
              :
                <>
                  <InputText label="Nom de l'entreprise" val={this.state.companyName} onChange={(e) => this.setState({companyName: e.target.value})} />
                  <InputText label="Siret" val={this.state.siret} onChange={(e) => this.setState({siret: e.target.value})} />
                </>
            }

            <HorizontalGroup className="newsletter-container">
              <input type="checkbox" id="newsletter" name="newsletter" checked={this.state.newsletter} onChange={(e) => this.setState({newsletter: e.target.checked})} />
              <label for="newsletter">Recevoir des mails de communication commerciale de notre part</label>
            </HorizontalGroup>

            <SimpleTextButton onClick={this._updateAccount}>Modifier mes informations</SimpleTextButton>

            <InputText type="password" label="Nouveau mot de passe" val={this.state.password1} onChange={(e) => this.setState({password1: e.target.value})} />
            <InputText type="password" label="Confirmer le nouveau mot de passe" val={this.state.password2}  onChange={(e) => this.setState({password2: e.target.value})} />
            <SimpleTextButton onClick={this._updatePassword}>Modifier le mot de passe</SimpleTextButton>
            
          </VerticalGroup>
        </PageSection>
      
      </Template>
  }
}