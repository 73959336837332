import Template from "../components/template";
import PageSection from "../components/pageSection";
import InputText from "../components/inputText";
import './../resources/css/global.css';
import './../resources/css/colors.css';
import './../resources/css/signup.css';
import SimpleTextButton from "../components/simpleTextButton";
import { isEmailValid, isPasswordValid, isPhoneNumberValid, distanceCoordinates, isSiretValid, isValidHttpCode, setStoredAccount, login, formatAddress } from "../utils/methods";
import { CENTER_LAT, CENTER_LNG, MAX_RADIUS } from "../utils/config";
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import HorizontalGroup from "../components/horizontalGroup";
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import VerticalGroup from "../components/verticalGroup";
import { apiFetcher } from "../utils/fetcher";
import Spacer from "../components/spacer";
import { useNavigate } from "react-router-dom";
import StringsManager from "../utils/stringsManager";
import SplashScreen from "../components/splashScreen";

export default class DynamicPage extends React.Component {

  constructor(props) {
    super(props);
    this.stringManager = StringsManager.getInstance();
  
    this.state = {
      appIsReady: false,
      loading: false,
      email: "",
      password1: "",
      password2: "",
      firstName: "",
      lastName: "",
      companyName: "",
      siret: "",
      phone: "",
      deliveryAddress: "",
      deliveryAddress2: "",
      deliveryAddress3: "",
      deliveryAddressWording: "",
      billingAddress: "",
      billingAddress2: "",
      billingAddress3: "",
      billingAddressWording: "",
      individualAccount: true,
      newsletter: false,
    }
  }

  signUp = async () => {
    this.setState({
      loading: true
    })
    let errors = [];


    if(this.state.deliveryAddress && this.state.deliveryAddress.label.length > 0){
      /*await geocodeByAddress(this.state.deliveryAddress.label)
      .then(results => getLatLng(results[0]))
      .then(({ lat, lng }) => {
          const dist = distanceCoordinates(lat, lng, CENTER_LAT, CENTER_LNG, "K")*1000;
          if(dist > MAX_RADIUS) {
            errors.push("L'adresse de livraison doit se trouver dans Paris.");
          }
        }
      );*/
      let deliveryAddressFormatted = await formatAddress(this.state.deliveryAddress, this.state.deliveryAddress2, this.state.deliveryAddress3, this.state.deliveryAddressWording);

      let isDeliveryAddressValid = await apiFetcher("/utils/isAddressValid", "POST", {
        address: deliveryAddressFormatted.adress1,
        postalCode: deliveryAddressFormatted.postalCode,
        city: deliveryAddressFormatted.city,
      });
      if(!isDeliveryAddressValid.body.isValid){
        errors.push('Cette adresse de livraison n\'est pas couverte par nos services.')
      }
    }
    else {
      errors.push("Veuillez renseigner une adresse de livraison.")
    }

    if(this.state.deliveryAddressWording.length == 0) {
      errors.push("Veuillez nommer l'adresse de livraison.")
    }

    if(this.state.billingAddress && this.state.billingAddress.label.length > 0){
      /*await geocodeByAddress(this.state.billingAddress.label)
      .then(results => getLatLng(results[0]))
      .then(({ lat, lng }) => {
          const dist = distanceCoordinates(lat, lng, CENTER_LAT, CENTER_LNG, "K")*1000;

          if(dist > MAX_RADIUS) {
            errors.push("L'adresse de facturation doit se trouver dans Paris.");
          }
        }
      );*/
    }
    else {
      errors.push("Veuillez renseigner une adresse de facturation.")
    }

    if(this.state.billingAddressWording.length == 0) {
      errors.push("Veuillez nommer l'adresse de facturation.")
    }

    if(!isEmailValid(this.state.email)){
      errors.push("Le format de l'adresse mail est invalide.");
    }
    if(!isPasswordValid(this.state.password1)){
      errors.push('Mot de passe invalide. Il doit contenir au moins 8 caractères dont une majuscule, une minuscule et un chiffre (pas de caractères spéciaux).');
    }
    if(this.state.password1.localeCompare(this.state.password2) !== 0){
      errors.push("Les mots de passe doivent correspondre.");
    }
    if(this.state.individualAccount){
      if(this.state.firstName.length == 0){
        errors.push("Veuillez renseigner un prénom.");
      }
      if(this.state.lastName.length == 0){
        errors.push("Veuillez renseigner un nom.");
      }
    }
    else {
      if(this.state.companyName.length == 0){
        errors.push("Veuillez renseigner un nom d'entreprise.");
      }
      if(!isSiretValid(this.state.siret)){
        errors.push("Veuillez renseigner un siret valide.");
      }
    }
    if(!isPhoneNumberValid(this.state.phone)){
      errors.push("Le format du numéro de téléphone n'est pas valide.");
    }
    
    if(errors.length > 0){
      this.setState({
        loading: false
      })
      for(let error of errors){
        toast.error(error, {
          position: "bottom-center"
        });
      }
    }
    else {
      if(errors.length == 0){
        let account = {
          email: this.state.email,
          password: this.state.password1,
          phone: this.state.phone,
          deliveryAddress: await formatAddress(this.state.deliveryAddress, this.state.deliveryAddress2, this.state.deliveryAddress3, this.state.deliveryAddressWording),
          billingAddress: await formatAddress(this.state.billingAddress, this.state.billingAddress2, this.state.billingAddress3, this.state.billingAddressWording),
          newsletter: this.state.newsletter
        }

        let subRoute = "/users/individual";

        if(this.state.individualAccount){
          account = {
            ...account,
            firstName: this.state.firstName,
            lastName: this.state.lastName,
          }
        }
        else {
          account = {
            ...account,
            name: this.state.companyName,
            siret: this.state.siret
          }
          subRoute = "/users/business";
        }


        apiFetcher(subRoute, "POST", account)
        .then(res => {
          toast.success("Compte créé.", {
            position: "bottom-center"
          });
          login(this.state.email, this.state.password1)
            .then(res => {
              this.setState({
                loading: false
              })
              if(res.body.token){
                setStoredAccount(res.body);
                toast.success("Connexion réussie.", {
                  position: "bottom-center"
                });
                window.location.href="/";
              }
            })
            .catch(err => {
              window.location.href="/";
              this.setState({
                loading: false
              })
            })
        })
        .catch(err => this.setState({
          loading: false
        }));
  
      }
    }

  };

  componentDidMount() {
    const loadingStringsInterval = setInterval(() => {
      if(this.stringManager.isLoaded()){
        this.setState({appIsReady: true})
        clearInterval(loadingStringsInterval);
      }
    }, 50)
  }

    render() { 
      return !this.state.appIsReady ? <SplashScreen /> : (
    <Template loading={this.state.loading} stringManager={this.stringManager} pageTitle="Inscription">

      <PageSection title="S'inscrire" className="signup-container" centerClassName="signup-center">

        <HorizontalGroup className="signup-container-accountTypes">
          <SimpleTextButton className={`${this.state.individualAccount ? "" : "simpletextbutton-container-dark-gray"}`} onClick={() => this.setState({individualAccount: true})}>Particulier</SimpleTextButton>
          <SimpleTextButton className={`${this.state.individualAccount ? "simpletextbutton-container-dark-gray" : ""}`} onClick={() => this.setState({individualAccount: false})}>Professionnel</SimpleTextButton>
        </HorizontalGroup>

        {
          this.state.individualAccount ?
            <HorizontalGroup>
              <InputText label="Prénom" val={this.state.firstName} onChange={(e) => this.setState({firstName: e.target.value})} />
              <InputText label="Nom" val={this.state.lastName} onChange={(e) => this.setState({lastName: e.target.value})} />
            </HorizontalGroup>
          :
            <HorizontalGroup>
              <InputText label="Nom de l'entreprise" val={this.state.companyName} onChange={(e) => this.setState({companyName: e.target.value})} />
              <InputText label="Siret" val={this.state.siret} onChange={(e) => this.setState({siret: e.target.value})} />
            </HorizontalGroup>
        }

        <Spacer size="small" />
        
        <HorizontalGroup>
          <VerticalGroup>
            <InputText label="Email" val={this.state.email} onChange={(e) => this.setState({email: e.target.value})} />
            <InputText label="Téléphone" val={this.state.phone} onChange={(e) => this.setState({phone: e.target.value})} />
          </VerticalGroup>
          <VerticalGroup>
            <InputText type="password" label="Mot de passe" val={this.state.password1} onChange={(e) => this.setState({password1: e.target.value})} />
            <InputText type="password" label="Confirmer le mot de passe" val={this.state.password2} onChange={(e) => this.setState({password2: e.target.value})} />
          </VerticalGroup>
        </HorizontalGroup>

        <Spacer size="small" />
        
        <HorizontalGroup>
          <VerticalGroup>
            <SimpleTextButton onClick={() => {this.setState({deliveryAddress: "", deliveryAddress2: "", deliveryAddress3: "", deliveryAddressWording: ""})}} className="simpletextbutton-container-small simpletextbutton-container-center simpletextbutton-container-red">Réinitialiser l'adresse</SimpleTextButton>
            <InputText label="Adresse de livraison" val={this.state.deliveryAddress} onChangeAddress={(e) => this.setState({deliveryAddress: e})} type="address" />
            <InputText label="Complément d'adresse 1" val={this.state.deliveryAddress2} onChange={(e) => this.setState({deliveryAddress2: e.target.value})} />
            <InputText label="Complément d'adresse 2" val={this.state.deliveryAddress3} onChange={(e) => this.setState({deliveryAddress3: e.target.value})} />
            <InputText label="Nom de l'adresse de livraison" val={this.state.deliveryAddressWording} onChange={(e) => this.setState({deliveryAddressWording: e.target.value})} />
          </VerticalGroup>
          <VerticalGroup>
            <SimpleTextButton onClick={() => {console.log("rein");this.setState({billingAddress: "", billingAddress2: "", billingAddress3: "", billingAddressWording: ""})}} className="simpletextbutton-container-small simpletextbutton-container-center simpletextbutton-container-red">Réinitialiser l'adresse</SimpleTextButton>
            <InputText label="Adresse de facturation" val={this.state.billingAddress} onChangeAddress={(e) => this.setState({billingAddress: e})} type="address" />
            <InputText label="Complément d'adresse 1" val={this.state.billingAddress2} onChange={(e) => this.setState({billingAddress2: e.target.value})} />
            <InputText label="Complément d'adresse 2" val={this.state.billingAddress3} onChange={(e) => this.setState({billingAddress3: e.target.value})} />
            <InputText label="Nom de l'adresse de facturation" val={this.state.billingAddressWording} onChange={(e) => this.setState({billingAddressWording: e.target.value})} />
          </VerticalGroup>
        </HorizontalGroup>

        <HorizontalGroup className="newsletter-container">
          <input type="checkbox" id="newsletter" name="newsletter" checked={this.state.newsletter} onChange={(e) => this.setState({newsletter: e.target.checked})} />
          <label for="newsletter">Recevoir des mails de communication commerciale de notre part</label>
        </HorizontalGroup>
        
        <SimpleTextButton onClick={this.signUp}>S'inscrire</SimpleTextButton>
      </PageSection>
    
    </Template>
  )}
}