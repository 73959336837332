import React from 'react'
import HorizontalGroup from "./../components/horizontalGroup";
import VerticalGroup from "./../components/verticalGroup";
import { AiFillStar } from 'react-icons/ai';
import { AiOutlineStar } from 'react-icons/ai';
import './../resources/css/colors.css';
import './../resources/css/ratingStarImageText.css';


function RatingStarImageText(props) {
    let nbStar = props.nbStar;
    if(!props.nbStar || props.nbStar > 5 || props.nbStar < 0) nbStar = 5;
    return (
        <div className="ratingstarimagetext-container">
            <VerticalGroup className="vertical-container">
                <HorizontalGroup className="personInfos">
                    <p className="personName">{props.personName}</p>
                    <img src={props.personImg} alt={props.personName} className="personImg" />
                </HorizontalGroup>
                <HorizontalGroup className="stars keep-orientation">
                    {[...Array(nbStar)].map((elem, index) => <AiFillStar size="30px" key={index} />)}
                    {[...Array(5-nbStar)].map((elem, index) => <AiOutlineStar size="30px" key={index} />)}
                </HorizontalGroup>
                <HorizontalGroup className="text-container">
                    <p className="text">{props.text}</p>
                </HorizontalGroup>
            </VerticalGroup>
        </div>
    )
}

export default RatingStarImageText;