import React from 'react'
import './../resources/css/colors.css';
import './../resources/css/iconText.css';

function IconText(props) {
    return (
        <div className="icontext-container">

            {props.icon}

            <h4 className="title">{props.title}</h4>

            <div className="description">{props.description}</div>

        </div>
    )
}

export default IconText;