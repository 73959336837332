import React from 'react'
import './../resources/css/colors.css';
import './../resources/css/profileOrder.css';
import HorizontalGroup from './horizontalGroup';
import { BsBasket } from 'react-icons/bs';
import VerticalGroup from './verticalGroup';
import { FaFileInvoice } from 'react-icons/fa';
import SimpleRoundIconButton from './simpleRoundIconButton';
import { API_URL } from '../utils/config';
import { timestampToStrDate } from '../utils/methods';

function ProfileOrder(props) {
    return (
        <HorizontalGroup className="profileOrder-container keep-orientation">
            <VerticalGroup className="profileOrder-container-icon">
                <BsBasket size="30px" />
            </VerticalGroup>
            <VerticalGroup className="profileOrder-infos-container">
                <p className="profileOrder-title">Commande #{props.id}</p>
                <p className="profileOrder-date"><strong>Récupération de la commande :</strong> {timestampToStrDate(props.pickupDate)}</p>
                <p className="profileOrder-date"><strong>Livraison de la commande :</strong> {timestampToStrDate(props.deliveryDate)}</p>
                <p className="profileOrder-date"><strong>Adresse de livraison :</strong> {props.pickupAddress}</p>
                <p className="profileOrder-date"><strong>Adresse de facturation :</strong> {props.billingAddress}</p>
                <p className="profileOrder-date"><strong>Status :</strong> {props.statusInfos.title}</p>
                <hr />
                { props.articles.length > 0 ?
                    (props.articles.map((item, index) => 
                        <p className="profileOrder-article">- {item.count} x {item.product} ({item.count*item.price}€ TTC)</p>
                    ))
                    : 
                    <p className="profileOrder-article">Aucun article enregistré.</p>
                }
                <p className="profileOrder-article">- 1 x Livraison ({props.deliveryPrice}€ TTC)</p>
                
            </VerticalGroup>
            <VerticalGroup className="profileOrder-container-invoice">
                {
                    props.statusInfos.id < 5 ?
                        <SimpleRoundIconButton onClick={() => alert("Votre facture sera disponible lorsque la commande sera reçue et vérifiée par nos services.")}>
                            <FaFileInvoice size="30px" />
                        </SimpleRoundIconButton>
                    :
                        <SimpleRoundIconButton onClick={() => window.location.href=`${API_URL}/orders/${props.invoiceToken}/invoice/download`}>
                            <FaFileInvoice size="30px" />
                        </SimpleRoundIconButton>
                }
            </VerticalGroup>
        </HorizontalGroup>
    )
}

//<hr />
//<HorizontalGroup className="profileOrder-prices-container">
//<p className="profileOrder-price">25€ HT</p>
//<p className="profileOrder-price">30€ TTC</p>
//</HorizontalGroup>

export default ProfileOrder;