import Template from "../components/template";
import PageSection from "../components/pageSection";
import './../resources/css/global.css';
import './../resources/css/colors.css';
import './../resources/css/profilePaymentMethods.css';
import CreditCard from "../components/creditCard";
import { useEffect, useState } from "react";
import { getStoredAccount, mustBeLoggedIn } from "../utils/methods";
import { apiFetcherLoggedIn } from "../utils/fetcher";

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import toast from "react-hot-toast";

import HorizontalGroup from "../components/horizontalGroup";
import SimpleTextButton from "../components/simpleTextButton";
import { Link, useNavigate } from "react-router-dom";

import StringsManager from "../utils/stringsManager";
import SplashScreen from "../components/splashScreen";
import React from "react";

export default class ProfilePaymentMethods extends React.Component {

  constructor(props) {
    super(props);
    this.stringManager = StringsManager.getInstance();
    this.state = {
      appIsReady: false,
      loading: false,
      paymentMethods: []
    }
  }

  _loadPaymentMethods = () => {
    this.setState({
      loading: true,
      paymentMethods: []
    })

    apiFetcherLoggedIn("/orders/listPaymentMethods", "GET")
      .then(paymentMethods => {
        this.setState({
          loading: false,
          paymentMethods: paymentMethods.body.data
        })
      })
      .catch(err =>  this.setState({
        loading: false,
      }))
  }

  _deletePaymentMethod = (paymentMethodId, wording) => {
    
    confirmAlert({
      title: 'Supprimer une carte de paiement',
      message: 'Voulez-vous vraiment supprimer la carte suivante : '+wording+' ?',
      buttons: [
        {
          label: 'Oui',
          onClick: () => {
            this.setState({
              loading: true,
            })

            apiFetcherLoggedIn("/orders/deletePaymentMethod", "DELETE", {
              id: paymentMethodId
            })
              .then(paymentMethods => {
                this._loadPaymentMethods();
                toast.success("Carte supprimée avec succès.", {
                  position: "bottom-center"
                });
              })
              .catch(err => this.setState({
                loading: false,
              }))
          }
        },
        {
          label: 'Non',
          onClick: () => console.log("no")
        }
      ]
    });
    
  }

  componentDidMount() {
    mustBeLoggedIn();
    this._loadPaymentMethods();

    const loadingStringsInterval = setInterval(() => {
      if(this.stringManager.isLoaded()){
        this.setState({appIsReady: true})
        clearInterval(loadingStringsInterval);
      }
    }, 50)
  }

  render() { 
    return !this.state.appIsReady ? <SplashScreen /> :
    <Template loading={this.state.loading} stringManager={this.stringManager} pageTitle="Profil - Moyens de paiement">

      <PageSection title="Mes moyens de paiement" className="profilepaymentmethods-container" centerClassName="profilepaymentmethods-center">
        
        <HorizontalGroup className="profilepaymentmethods-container-horizontal horizontalgroup-containe-mobile-center">
          <Link to="/profile"><SimpleTextButton>Retour au profil</SimpleTextButton></Link>
        </HorizontalGroup>
        
        { this.state.paymentMethods.length > 0 ?
          (this.state.paymentMethods.map((item, index) => (
            <CreditCard 
              key={item.id}
              deleteAction={() => this._deletePaymentMethod(item.id, item.billing_details.name)} 
              title={item.billing_details.name}
              content={"xxxx xxxx xxxx "+item.card.last4}
              subcontent={item.card.exp_month+"/"+item.card.exp_year} />
          )))
          : 
          <span>Aucune carte enregistrée.</span>
        }
      </PageSection>
    
    </Template>
  }
}