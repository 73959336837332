import Template from "../components/template";
import PageSection from "../components/pageSection";
import './../resources/css/global.css';
import './../resources/css/colors.css';
import './../resources/css/profileAddresses.css';
import Address from "../components/address";
import { getStoredAccount, mustBeLoggedIn } from "../utils/methods";
import { useEffect, useState } from "react";
import { apiFetcherLoggedIn } from "../utils/fetcher";

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import toast from "react-hot-toast";
import HorizontalGroup from "../components/horizontalGroup";
import SimpleTextButton from "../components/simpleTextButton";
import { Link, useNavigate } from "react-router-dom";
import StringsManager from "../utils/stringsManager";
import SplashScreen from "../components/splashScreen";
import React from "react";

export default class ProfileAddresses extends React.Component {

  constructor(props) {
    super(props);
    this.stringManager = StringsManager.getInstance();
    this.state = {
      appIsReady: false,
      loading: false,
      addresses: []
    }
    this.account = getStoredAccount();
  }

  _loadAddresses = () => {
    this.setState({
      loading: true,
      addresses: []
    })

    apiFetcherLoggedIn("/users/"+this.account.id+"/address", "GET")
    .then(addresses => {
      this.setState({
        loading: false,
        addresses: addresses.body
      })
    })
    .catch(err => this.setState({
      loading: false,
    }))
  }

  deleteAddress = (addressId, wording) => {
    confirmAlert({
      title: 'Supprimer une adresse',
      message: 'Voulez-vous vraiment supprimer l\'adresse suivante : '+wording+' ?',
      buttons: [
        {
          label: 'Oui',
          onClick: () => {
            this.setState({
              loading: true,
            })
            apiFetcherLoggedIn("/address/"+addressId, "DELETE", {})
              .then(addressInfo => {
                this.setState({
                  loading: false,
                })
                this._loadAddresses();
                
                toast.success("Adresse supprimée avec succès.", {
                  position: "bottom-center"
                });
              })
              .catch(err => 
                this.setState({
                  loading: false,
                }))
          }
        },
        {
          label: 'Non',
          onClick: () => console.log("no")
        }
      ]
    });
  };

  componentDidMount() {
    mustBeLoggedIn();
    this._loadAddresses();

    const loadingStringsInterval = setInterval(() => {
      if(this.stringManager.isLoaded()){
        this.setState({appIsReady: true})
        clearInterval(loadingStringsInterval);
      }
    }, 50)
  }

  render() { 
    return !this.state.appIsReady ? <SplashScreen /> :
    <Template loading={this.state.loading} stringManager={this.stringManager} pageTitle="Profil - Adresses">

      <PageSection title="Mes adresses" className="profileaddresses-container" centerClassName="profileaddresses-center">
          
          <HorizontalGroup className="profileaddresses-container-horizontal horizontalgroup-containe-mobile-center">
            <Link to="/profile"><SimpleTextButton>Retour au profil</SimpleTextButton></Link>
          </HorizontalGroup>

          { this.state.addresses.length > 0 ?
            (this.state.addresses.map((item, index) => (
              <Address 
                key={index}
                deleteAction={() => this.deleteAddress(item.id, item.wording)} 
                title={item.wording}
                content={item.adress1+" "+item.postalCode+" "+item.city}
                subcontent1={item.adress2}
                subcontent2={item.adress3} />
            )))
            : 
            <span>Aucune adresse enregistrée.</span>
          }

      </PageSection>
    
    </Template>
  }
}