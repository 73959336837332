import React from 'react'
import './../resources/css/colors.css';
import './../resources/css/inputText.css';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import StringsManager from "../utils/stringsManager";

function InputText(props) {
    let stringManager = StringsManager.getInstance();
    return (
        <label className="labelInputText">
            {props.label}
            {
                props.large ? 
                    <textarea disabled={props.disabled ? props.disabled : false} onChange={props.onChange ? props.onChange : () => {}} className={`inputText ${props.error ? "inputText-error" : ""} ${props.success ? "inputText-success" : ""}`} value={props.val}></textarea>
                :
                props.type && props.type.localeCompare("address") == 0 ?
                    <GooglePlacesAutocomplete
                        apiKey={stringManager.getVariable("GOOGLE_TOKEN").value}
                        autocompletionRequest={{
                            language: 'fr',
                            types: ["address"],
                            location: { lat: 48.875873, lng: 2.343092 },
                            radius: 10000, //10 km
                            componentRestrictions: {
                            country: ['fr'],
                            }
                            //strictbounds: true,
                        }}
                        selectProps={{
                            value: props.val,
                            onChange: props.onChangeAddress,
                            styles: {
                                control: (provided) => ({
                                    ...provided,
                                    color: 'blue',
                                    width: (window.innerWidth >= 424) ? 424 : (window.innerWidth-50),
                                    height: 35,
                                    borderWidth: 2,
                                    borderStyle: "solid",
                                    borderColor: "#2bb6ec",
                                    borderRadius: 5,
                                    fontFamily: "Montserrat, sans-serif",
                                    fontSize: 16,
                                    color: "#000000",
                                    paddingLeft: 10,
                                    paddingRight: 10,
                                    fontWeight: 400,
                                    marginTop: 5,
                                }),
                            },
                        }}
                    />
                :
                    <input disabled={props.disabled ? props.disabled : false} type={props.type ? props.type : "text"} value={props.val ? props.val : ""} onChange={props.onChange ? props.onChange : () => {}} className={`inputText ${props.error ? "inputText-error" : ""} ${props.success ? "inputText-success" : ""}`} />
            }
         </label>
    )
}

export default InputText;