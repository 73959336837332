import React from 'react'
import './../resources/css/colors.css';
import './../resources/css/address.css';
import HorizontalGroup from './horizontalGroup';
import { AiOutlineEdit } from 'react-icons/ai';
import { IoMdAddCircleOutline } from 'react-icons/io';
import { MdDeleteOutline } from 'react-icons/md';
import { AiOutlineEye } from 'react-icons/ai';
import { AiOutlineHome } from 'react-icons/ai';
import VerticalGroup from './verticalGroup';

function Address(props) {
    var action = () => {};
    var icon = <></>;
    if(props.addAction){
        action = props.addAction;
        icon = <IoMdAddCircleOutline size="30px" />;
    }
    else if(props.editAction){
        action = props.editAction;
        icon = <AiOutlineEdit size="30px" />;
    }
    else if(props.deleteAction){
        action = props.deleteAction;
        icon = <MdDeleteOutline size="30px" />;
    }
    else if(props.viewAction){
        action = props.viewAction;
        icon = <AiOutlineEye size="30px" />;
    }
    return (
        <HorizontalGroup onClick={() => action()} className="address-container keep-orientation">
            <VerticalGroup className="address-container-icon">
                <AiOutlineHome size="30px" />
            </VerticalGroup>
            <VerticalGroup className="address-infos-container">
                {props.title ? <p className="address-title">{props.title}</p> : <></>}
                {props.content ? <p className="address-content">{props.content}</p> : <></>}
                {props.subcontent1 ? <p className="address-subcontent">{props.subcontent1}</p> : <></>}
                {props.subcontent2 ? <p className="address-subcontent">{props.subcontent2}</p> : <></>}
            </VerticalGroup>
            <HorizontalGroup className="address-action-container keep-orientation">
                {icon}
            </HorizontalGroup>
        </HorizontalGroup>
    )
}

export default Address;