import Template from "../components/template";
import PageSection from "../components/pageSection";
import './../resources/css/global.css';
import './../resources/css/colors.css';
import './../resources/css/profileOrders.css';
import ProfileOrder from "../components/profileOrder";

import HorizontalGroup from "../components/horizontalGroup";
import SimpleTextButton from "../components/simpleTextButton";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { apiFetcherLoggedIn } from "../utils/fetcher";
import { getStoredAccount, mustBeLoggedIn } from "../utils/methods";
import { useEffect } from "react";


import StringsManager from "../utils/stringsManager";
import SplashScreen from "../components/splashScreen";
import React from "react";

export default class ProfileOrders extends React.Component {

  constructor(props) {
    super(props);
    this.stringManager = StringsManager.getInstance();
    this.state = {
      appIsReady: false,
      loading: false,
      orders: []
    }
  }

  _loadOrders = () => {
    this.setState({
      loading: true,
      orders: []
    })
    let account = getStoredAccount();

    apiFetcherLoggedIn("/users/"+account.id+"/orders", "GET")
      .then(allOrders => {
        allOrders.body = allOrders.body.sort((order1, order2) => {
          return order1.id < order2.id
        })
        this.setState({
          loading: false,
          orders: allOrders.body
        })
      })
      .catch(err => this.setState({
        loading: false,
      }))
  }

  componentDidMount() {
    mustBeLoggedIn();
    this._loadOrders();

    const loadingStringsInterval = setInterval(() => {
      if(this.stringManager.isLoaded()){
        this.setState({appIsReady: true})
        clearInterval(loadingStringsInterval);
      }
    }, 50)
  }

  render() { 
    return !this.state.appIsReady ? <SplashScreen /> :
    <Template loading={this.state.loading} stringManager={this.stringManager} pageTitle="Profil - Commandes">

      <PageSection title="Mes commandes" className="profileorders-container" centerClassName="profileorders-center">
        
        <HorizontalGroup className="profileorders-container-horizontal horizontalgroup-containe-mobile-center">
          <Link to="/profile"><SimpleTextButton>Retour au profil</SimpleTextButton></Link>
        </HorizontalGroup>
        
        { this.state.orders.length > 0 ?
          (this.state.orders.map((item, index) => {
            return (
              <ProfileOrder 
                id={item.id}
                invoiceToken={item.invoiceToken}
                articles={item.products}
                pickupAddress={`${item.pickupAddress.adress1} ${item.pickupAddress.postalCode} ${item.pickupAddress.city}`}
                billingAddress={`${item.pickupAddress.adress1} ${item.pickupAddress.postalCode} ${item.pickupAddress.city}`}
                pickupDate={item.pickUpDate}
                deliveryDate={item.deliveredDate}
                deliveryPrice={item.deliveryPrice}
                statusInfos={item.statusInfos} />
          )}))
          : 
          <span>Aucune commande enregistrée.</span>
        }
      </PageSection>
    
    </Template>
  }
}