import React from 'react'
import './../resources/css/spacer.css';

function Spacer(props) {
    return (
        <div className={`spacer-container spacer-container-${props.size ? props.size : "small"}`}>

        </div>
    )
}

export default Spacer;