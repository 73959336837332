import React from 'react'
import './../resources/css/colors.css';
import './../resources/css/counterText.css';

import CountUp from 'react-countup';

function CounterText(props) {
    return (
        <div className="countertext-container">

            <CountUp start={props.counterStart} end={props.counterEnd} delay={0}>
                {({ countUpRef }) => (
                    <span ref={countUpRef} />
                )}
            </CountUp> 

            <p>{props.text}</p>

        </div>
    )
}

export default CounterText;