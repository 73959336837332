import React from 'react'
import logo from './../resources/img/logo.png';
import './../resources/css/colors.css';
import './../resources/css/global.css';
import './../resources/css/bar.css';
import { useState, useEffect } from 'react';
import useScrollDimensions from "../hooks/scrollDimensions";
import HorizontalGroup from './horizontalGroup';

import { Link } from "react-router-dom";

import { MdLogin, MdLogout } from 'react-icons/md';
import { IoMdLogIn } from 'react-icons/io';
import { GiHamburgerMenu } from 'react-icons/gi';
import { AiOutlineUser } from 'react-icons/ai';
import SimpleTextButton from './simpleTextButton';

import { clearBasket, removeStoredAccount } from "./../utils/methods";
import { useNavigate } from "react-router-dom";

function Bar(props) {
    let navigate = useNavigate();
    
    const { scrollHeight, scrollWidth } = useScrollDimensions();

    const [barClass, setBarClass] = useState("");
    const [responsiveBarClass, setResponsiveBarClass] = useState("reduced");

    useEffect(() => {
        scrollHeight > 0 ? setBarClass("active") : setBarClass("");
    }, [barClass, setBarClass, scrollHeight])

    function toggleResponsiveMenu(){
        if(responsiveBarClass.localeCompare("reduced") == 0){
            setResponsiveBarClass("full");
        }
        else {
            setResponsiveBarClass("reduced");
        }
    }
    
    return (
        <nav className={`bar ${barClass} ${responsiveBarClass}`}>
            <HorizontalGroup className="center">
                <Link to="/"><img src={logo} alt="logo" className="logo" /></Link>

                <HorizontalGroup className="links">
                    {
                        !props.connected ?
                            <>
                                <Link to="/login"><a href="#"><MdLogin size={"30px"} />Connexion</a></Link>
                                <Link to="/signup"><a href="#"><IoMdLogIn size={"30px"} />Inscription</a></Link>
                            </>
                        :
                            <>
                                <Link to="/order"><SimpleTextButton>Commander</SimpleTextButton></Link>
                                <Link to="/profile"><a href="#"><AiOutlineUser size={"30px"} />Mon compte</a></Link>
                                <a href="#" onClick={() => {removeStoredAccount(); clearBasket(); window.location.href = "/"; }}><MdLogout size={"30px"} />Déconnexion</a>
                            </>
                    }
                </HorizontalGroup>

                <GiHamburgerMenu onClick={toggleResponsiveMenu} class="bar-menu-button" size="30px" />
            </HorizontalGroup>
        </nav>
    );
}

export default Bar