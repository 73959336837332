import React from 'react'
import './../resources/css/colors.css';
import './../resources/css/orderProduct.css';
import HorizontalGroup from './horizontalGroup';
import VerticalGroup from './verticalGroup';

function OrderProduct(props) {
    return (
        <HorizontalGroup className="orderproduct-container keep-orientation">
            <VerticalGroup className="orderproduct-title-container">
                <p className="orderproduct-title">{props.name}</p>
                <p className="orderproduct-subtitle">{props.subtitle}</p>
                <p className="orderproduct-subtitle2">{props.minDelay} jours minimum</p>
            </VerticalGroup>
            <HorizontalGroup className="orderproduct-price-container keep-orientation">
                <p className="orderproduct-price-container-number">{props.price}€</p>
            </HorizontalGroup>
            <HorizontalGroup className="orderproduct-quantity-container keep-orientation">
                <a className="orderproduct-quantity-container-minus" onClick={props.minusAction}>-</a>
                <p className="orderproduct-quantity-container-number">x{props.count}</p>
                <a className="orderproduct-quantity-container-plus" onClick={props.plusAction}>+</a>
            </HorizontalGroup>
        </HorizontalGroup>
    )
}

export default OrderProduct;