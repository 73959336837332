import React from 'react'
import './../resources/css/colors.css';
import './../resources/css/global.css';
import './../resources/css/footer.css';

import { GoMail } from 'react-icons/go';
import { BsTelephone } from 'react-icons/bs';
import { HiOutlineLocationMarker } from 'react-icons/hi';
import { FiFacebook } from 'react-icons/fi';
import { FiTwitter } from 'react-icons/fi';
import { BsInstagram } from 'react-icons/bs';
import VerticalGroup from './verticalGroup';
import HorizontalGroup from './horizontalGroup';
import SimpleRoundIconButton from './simpleRoundIconButton';

function Header(props) {
    
    return (
        <footer className="footer">
            <div class="center">

                <VerticalGroup className="footer-container">
                    <VerticalGroup className="footer-contactinfos">
                        <div className="footer-contactinfo">
                            <GoMail size="30px" />
                            {props.stringManager.getMessage("GLOBAL_PRESSANDCO_EMAIL")}
                        </div>   
                        <div className="footer-contactinfo">
                            <BsTelephone size="30px" />
                            {props.stringManager.getMessage("GLOBAL_PRESSANDCO_PHONE")}
                        </div>   
                        <div className="footer-contactinfo">
                            <HiOutlineLocationMarker size="30px" />
                            {props.stringManager.getMessage("GLOBAL_PRESSANDCO_ADDRESS")}
                        </div>   
                    </VerticalGroup>

                    <hr />

                    <HorizontalGroup className="footer-linkblocs">
                        <VerticalGroup className="footer-linkbloc">
                            <h4>{props.stringManager.getCategory(1)}</h4>
                            {
                                Object.values(props.stringManager.getContents()).filter(e => e.category_id === 1)
                                .map(content => {
                                    return <a href={"/page/"+content.slug}>{content.title}</a>
                                })
                            }
                        </VerticalGroup>
                        <VerticalGroup className="footer-linkbloc">
                            <h4>{props.stringManager.getCategory(2)}</h4>
                            {
                                Object.values(props.stringManager.getContents()).filter(e => e.category_id === 2)
                                .map(content => {
                                    return <a href={"/page/"+content.slug}>{content.title}</a>
                                })
                            }
                        </VerticalGroup>
                        <VerticalGroup className="footer-linkbloc">
                            <h4>{props.stringManager.getCategory(3)}</h4>
                            {
                                Object.values(props.stringManager.getContents()).filter(e => e.category_id === 3)
                                .map(content => {
                                    return <a href={"/page/"+content.slug}>{content.title}</a>
                                })
                            }
                        </VerticalGroup>
                    </HorizontalGroup>

                    <hr />

                    <VerticalGroup className="footer-texts">
                        <p>
                            {props.stringManager.getMessage("WEBSITE_FOOTER_TEXT_1")}
                        </p>
                        <p>
                            {props.stringManager.getMessage("WEBSITE_FOOTER_TEXT_2")}
                        </p>
                        <p>
                            {props.stringManager.getMessage("WEBSITE_FOOTER_TEXT_3")}
                        </p>
                        <p>
                            {props.stringManager.getMessage("WEBSITE_FOOTER_TEXT_4")}
                        </p>
                    </VerticalGroup>

                    <HorizontalGroup className="footer-networks keep-orientation">
                        <SimpleRoundIconButton onClick={() => window.location.href=props.stringManager.getMessage("GLOBAL_PRESSANDCO_FACEBOOK")}><FiFacebook size="30px" /></SimpleRoundIconButton>
                        <SimpleRoundIconButton onClick={() => window.location.href=props.stringManager.getMessage("GLOBAL_PRESSANDCO_TWITTER")}><FiTwitter size="30px" /></SimpleRoundIconButton>
                        <SimpleRoundIconButton onClick={() => window.location.href=props.stringManager.getMessage("GLOBAL_PRESSANDCO_INSTAGRAM")}><BsInstagram size="30px" /></SimpleRoundIconButton>
                    </HorizontalGroup>

                    <VerticalGroup className="footer-copyright">
                        <p>®TOUS DROITS RÉSERVÉS PRESSANDCO 2010-{new Date().getFullYear()}</p>
                    </VerticalGroup>

                </VerticalGroup>

            </div>
        </footer>
    );
}

export default Header