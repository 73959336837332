import Template from "../components/template";
import PageSection from "../components/pageSection";
import InputText from "../components/inputText";
import './../resources/css/global.css';
import './../resources/css/colors.css';
import './../resources/css/login.css';
import SimpleTextButton from "../components/simpleTextButton";

import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { apiFetcher } from "../utils/fetcher";

import { useNavigate } from "react-router-dom";


import { setStoredAccount, login } from "../utils/methods";
import StringsManager from "../utils/stringsManager";
import SplashScreen from "../components/splashScreen";


export default class Login extends React.Component {

  constructor(props) {
    super(props);
    this.stringManager = StringsManager.getInstance();
    this.state = {
      appIsReady: false,
      loading: false,
      email: "",
      password: ""
    }
  }

  enterEvent(e) {
    document.addEventListener("keydown", (e) => {
      if(e.code === "Enter" && window.location.href.includes("/login")){
        console.log("window.location.href", window.location.href, window.location.href.includes("/login"))
        this._login();
      }
    });
  }

  componentDidMount() {
    const loadingStringsInterval = setInterval(() => {
      if(this.stringManager.isLoaded()){
        this.setState({appIsReady: true})
        clearInterval(loadingStringsInterval);
      }
    }, 50)

    document.addEventListener("keydown", this.enterEvent);
  }

  componentWillUnmount(){
    document.removeEventListener("keydown", this.enterEvent);
  }

  _login = () => {
    this.setState({loading: true});

    login(this.state.email, this.state.password)
    .then(res => {
      this.setState({loading: false});
      setStoredAccount(res.body);
      toast.success("Connexion réussie.", {
        position: "bottom-center"
      });
      window.location.href = "/";
    })
    .catch(err => this.setState({loading: false}))
  }

  render() { 
    return !this.state.appIsReady ? <SplashScreen /> : (
      <Template loading={this.state.loading} stringManager={this.stringManager} pageTitle="Connexion">

        <PageSection title="Se connecter" className="login-container" centerClassName="login-center">
          <InputText label="Email" onChange={(e) => this.setState({email: e.target.value})} val={this.state.email} />
          <InputText type="password" label="Mot de passe" onChange={(e) => this.setState({password: e.target.value})} val={this.state.password} />
          <a href="/passwordLost">Mot de passe perdu ?</a>
          <SimpleTextButton onClick={() => this._login()}>Se connecter</SimpleTextButton>
        </PageSection>
      
      </Template>
    )}
  }