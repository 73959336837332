import React from 'react'
import './../resources/css/colors.css';
import './../resources/css/simpleRoundIconButton.css';

function SimpleRoundIconButton(props) {
    return (
        <button onClick={props.onClick} className={`simpleroundiconbutton-container ${props.className ? props.className : ""}`}>
            {props.children}
        </button>
    )
}

export default SimpleRoundIconButton;