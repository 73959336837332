import Header from "./../components/header";
import Template from "./../components/template";
import IconLineText from "./../components/iconLineText";
import HorizontalGroup from "./../components/horizontalGroup";
import VerticalGroup from "./../components/verticalGroup";
import PageSection from "./../components/pageSection";
import IconText from "./../components/iconText";
import CounterText from "./../components/counterText";
import './../resources/css/global.css';
import './../resources/css/home.css';

import { GiSmartphone } from 'react-icons/gi';
import { MdOutlineIron } from 'react-icons/md';
import { IoShirtOutline } from 'react-icons/io5';
import { BsLightningCharge } from 'react-icons/bs';
import { IoMedalOutline } from 'react-icons/io5';
import { RiEBike2Line } from 'react-icons/ri';
import RatingStarImageText from "./../components/ratingStarImageText";

import person1 from './../resources/img/person1.png';
import person2 from './../resources/img/person2.png';
import person3 from './../resources/img/person3.png';

import zonePressandco from "./../resources/img/zone-pressandco2.png";
import androidBadge from "./../resources/img/google-play-badge.png";
import iosBadge from "./../resources/img/badge_appstore.png";
import SimpleTextButton from "./../components/simpleTextButton";
import { useEffect } from "react";
import { timestampToStrDate, getUTCTimestamp } from "./../utils/methods";
import moment from "moment";
import StringsManager from "../utils/stringsManager";
import SplashScreen from "../components/splashScreen";
import React from "react";

export default class Home extends React.Component {

  constructor(props) {
    super(props);
    this.stringManager = StringsManager.getInstance();
    this.state = {
      appIsReady: false
    }
  }

  componentDidMount() {
    const loadingStringsInterval = setInterval(() => {
      if(this.stringManager.isLoaded()){
        this.setState({appIsReady: true})
        clearInterval(loadingStringsInterval);
      }
    }, 50)
  }

  render() { 
    return !this.state.appIsReady ? <SplashScreen /> :
    <Template stringManager={this.stringManager} pageTitle="Accueil">
      <Header stringManager={this.stringManager} />
      <PageSection title={this.stringManager.getMessage("WEBSITE_HOME_SECTION_1_TITLE")} description={this.stringManager.getMessage("HOME_SECTION_1_DESCRIPTION")}>
        <HorizontalGroup>
          <IconLineText 
            leftMode="true" 
            icon={<GiSmartphone size="80px" />} 
            title={this.stringManager.getMessage("WEBSITE_HOME_SECTION_1_ITEM_1_TITLE")}
            description={this.stringManager.getMessage("WEBSITE_HOME_SECTION_1_ITEM_1_CONTENT")}
          />
          <IconLineText 
            icon={<MdOutlineIron size="80px" />} 
            title={this.stringManager.getMessage("WEBSITE_HOME_SECTION_1_ITEM_2_TITLE")}
            description={this.stringManager.getMessage("WEBSITE_HOME_SECTION_1_ITEM_2_CONTENT")}
            />
          <IconLineText 
            rightMode="true" 
            icon={<IoShirtOutline size="80px" />} 
            title={this.stringManager.getMessage("WEBSITE_HOME_SECTION_1_ITEM_3_TITLE")}
            description={this.stringManager.getMessage("WEBSITE_HOME_SECTION_1_ITEM_3_CONTENT")}
            />
        </HorizontalGroup>
      </PageSection>
      <PageSection color="gray" title={this.stringManager.getMessage("WEBSITE_HOME_SECTION_2_TITLE")} description={this.stringManager.getMessage("WEBSITE_HOME_SECTION_2_DESCRIPTION")}>
        <HorizontalGroup>
          <IconText 
            icon={<BsLightningCharge size="80px" />} 
            title={this.stringManager.getMessage("WEBSITE_HOME_SECTION_2_ITEM_1_TITLE")}
            description={
              <>
                <p>{this.stringManager.getMessage("WEBSITE_HOME_SECTION_2_ITEM_1_CONTENT_1")}</p>
                <p>{this.stringManager.getMessage("WEBSITE_HOME_SECTION_2_ITEM_1_CONTENT_2")}</p>
              </>
            }
          />
          <IconText 
            icon={<IoMedalOutline size="80px" />} 
            title={this.stringManager.getMessage("WEBSITE_HOME_SECTION_2_ITEM_2_TITLE")}
            description={
              <>
                <p>{this.stringManager.getMessage("WEBSITE_HOME_SECTION_2_ITEM_2_CONTENT_1")}</p>
                <p>{this.stringManager.getMessage("WEBSITE_HOME_SECTION_2_ITEM_2_CONTENT_2")}</p>
              </>
            }
          />
          <IconText 
            icon={<RiEBike2Line size="80px" />} 
            title={this.stringManager.getMessage("WEBSITE_HOME_SECTION_2_ITEM_3_TITLE")}
            description={
              <>
                <p>{this.stringManager.getMessage("WEBSITE_HOME_SECTION_2_ITEM_3_CONTENT_1")}</p>
                <p>{this.stringManager.getMessage("WEBSITE_HOME_SECTION_2_ITEM_3_CONTENT_2")}</p>
              </>
            }
          />
        </HorizontalGroup>
      </PageSection>
      <PageSection title={this.stringManager.getMessage("WEBSITE_HOME_SECTION_3_TITLE")} description={this.stringManager.getMessage("WEBSITE_HOME_SECTION_3_DESCRIPTION")}>
        <VerticalGroup>
          <HorizontalGroup>
            <CounterText counterStart={0} counterEnd={parseInt(this.stringManager.getMessage("WEBSITE_HOME_SECTION_3_ITEM_1_NUMBER"))} text={this.stringManager.getMessage("WEBSITE_HOME_SECTION_3_ITEM_1_DESCRIPTION")} />
            <CounterText counterStart={0} counterEnd={parseInt(this.stringManager.getMessage("WEBSITE_HOME_SECTION_3_ITEM_2_NUMBER"))} text={this.stringManager.getMessage("WEBSITE_HOME_SECTION_3_ITEM_2_DESCRIPTION")} />
            <CounterText counterStart={0} counterEnd={parseInt(this.stringManager.getMessage("WEBSITE_HOME_SECTION_3_ITEM_3_NUMBER"))} text={this.stringManager.getMessage("WEBSITE_HOME_SECTION_3_ITEM_3_DESCRIPTION")} />
            <CounterText counterStart={0} counterEnd={parseInt(this.stringManager.getMessage("WEBSITE_HOME_SECTION_3_ITEM_4_NUMBER"))} text={this.stringManager.getMessage("WEBSITE_HOME_SECTION_3_ITEM_4_DESCRIPTION")} />
          </HorizontalGroup>
          <HorizontalGroup>
            <RatingStarImageText 
              personName={this.stringManager.getMessage("WEBSITE_HOME_SECTION_3_RATING_1_NAME")}
              personImg={this.stringManager.getMessage("WEBSITE_HOME_SECTION_3_RATING_1_PHOTO")} 
              nbStar={parseInt(this.stringManager.getMessage("WEBSITE_HOME_SECTION_3_RATING_1_STARS"))} 
              text={this.stringManager.getMessage("WEBSITE_HOME_SECTION_3_RATING_1_TEXT")} />
            <RatingStarImageText 
              personName={this.stringManager.getMessage("WEBSITE_HOME_SECTION_3_RATING_2_NAME")}
              personImg={this.stringManager.getMessage("WEBSITE_HOME_SECTION_3_RATING_2_PHOTO")} 
              nbStar={parseInt(this.stringManager.getMessage("WEBSITE_HOME_SECTION_3_RATING_2_STARS"))} 
              text={this.stringManager.getMessage("WEBSITE_HOME_SECTION_3_RATING_2_TEXT")} />
            <RatingStarImageText 
              personName={this.stringManager.getMessage("WEBSITE_HOME_SECTION_3_RATING_3_NAME")}
              personImg={this.stringManager.getMessage("WEBSITE_HOME_SECTION_3_RATING_3_PHOTO")} 
              nbStar={parseInt(this.stringManager.getMessage("WEBSITE_HOME_SECTION_3_RATING_3_STARS"))} 
              text={this.stringManager.getMessage("WEBSITE_HOME_SECTION_3_RATING_3_TEXT")} />
          </HorizontalGroup>
        </VerticalGroup>
      </PageSection>
      <PageSection color="gray" className="informations" title={this.stringManager.getMessage("WEBSITE_HOME_SECTION_4_TITLE")} description={this.stringManager.getMessage("WEBSITE_HOME_SECTION_4_DESCRIPTION")}>
        <HorizontalGroup className="informations-global-container">
          <VerticalGroup className="informations-global-second-container">
            <h3 class="informations-title">{this.stringManager.getMessage("WEBSITE_HOME_SECTION_4_PART_1_TITLE")}</h3>
            <p class="informations-description">{this.stringManager.getMessage("WEBSITE_HOME_SECTION_4_PART_1_DESCRIPTION")}</p>
            <img src={zonePressandco} alt="zone pressandco" className="informations-zonepressandco" />
          </VerticalGroup>
          <VerticalGroup className="informations-global-second-container">
            <h3 class="informations-title">{this.stringManager.getMessage("WEBSITE_HOME_SECTION_4_PART_2_TITLE")}</h3>
            <p class="informations-description">{this.stringManager.getMessage("WEBSITE_HOME_SECTION_4_PART_2_DESCRIPTION")}</p>
            <HorizontalGroup className="badges keep-orientation">
              <a href={this.stringManager.getMessage("GLOBAL_PRESSANDCO_APP_IOS")} className="informations-badge">
                <img src={iosBadge} alt="iosBadge" />
              </a>
              <a href={this.stringManager.getMessage("GLOBAL_PRESSANDCO_APP_ANDROID")} className="informations-badge">
                <img src={androidBadge} alt="androidBadge" />
              </a>
            </HorizontalGroup>

            <hr />

            <SimpleTextButton onClick={() => window.location.href="/contact"} className="informations-contactbutton">Nous contacter</SimpleTextButton>
          </VerticalGroup>
        </HorizontalGroup>
      </PageSection>
      
    </Template>
  }
}