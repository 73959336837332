import React, { useEffect, useState } from 'react'
import Bar from "./bar";
import Footer from "./footer";
import GoToTopButton from "./goToTopButton";
import './../resources/css/template.css';
import toast, { Toaster } from 'react-hot-toast';
import { isLoggedIn } from "../utils/methods";
import loading from './../resources/img/loading.gif';
import './../resources/css/splashScreen.css';

function SplashScreen(props) {

    return (
      <div className="splashscreen-container">
        <img src={loading} alt="loading" style={{width: 100, height: 100}} />
      </div>
    )
}

export default SplashScreen;