import React from 'react'
import './../resources/css/colors.css';
import './../resources/css/creditCard.css';
import HorizontalGroup from './horizontalGroup';
import { AiOutlineEdit } from 'react-icons/ai';
import { IoMdAddCircleOutline } from 'react-icons/io';
import { MdDeleteOutline } from 'react-icons/md';
import { AiOutlineEye } from 'react-icons/ai';
import { AiOutlineCreditCard } from 'react-icons/ai';
import VerticalGroup from './verticalGroup';

function CreditCard(props) {
    var action = () => {};
    var icon = <></>;
    if(props.addAction){
        action = props.addAction;
        icon = <IoMdAddCircleOutline size="30px" />;
    }
    else if(props.editAction){
        action = props.editAction;
        icon = <AiOutlineEdit size="30px" />;
    }
    else if(props.deleteAction){
        action = props.deleteAction;
        icon = <MdDeleteOutline size="30px" />;
    }
    else if(props.viewAction){
        action = props.viewAction;
        icon = <AiOutlineEye size="30px" />;
    }
    return (
        <HorizontalGroup onClick={() => action()} className="creditCard-container keep-orientation">
            <VerticalGroup className="creditCard-container-icon">
                <AiOutlineCreditCard size="30px" />
            </VerticalGroup>
            <VerticalGroup className="creditCard-infos-container">
                {props.title ? <p className="creditCard-title">{props.title}</p> : <></>}
                {props.content ? <p className="creditCard-content">{props.content}</p> : <></>}
                {props.subcontent ? <p className="creditCard-subcontent">{props.subcontent}</p> : <></>}
            </VerticalGroup>
            <HorizontalGroup className="creditCard-action-container keep-orientation">
                {icon}
            </HorizontalGroup>
        </HorizontalGroup>
    )
}

export default CreditCard;