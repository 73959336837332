import React from 'react'
import './../resources/css/colors.css';
import './../resources/css/simpleTextButton.css';

function SimpleTextButton(props) {
    return (
        <button onClick={props.onClick} className={`simpletextbutton-container ${props.className ? props.className : ""}`}>
            {props.children}
        </button>
    )
}

export default SimpleTextButton;