import React from 'react'
import './../resources/css/colors.css';
import './../resources/css/global.css';
import './../resources/css/pageSection.css';

function PageSection(props) {
    return (
        <section className={`pagesection-container ${props.color ? props.color : ""} ${props.className ? props.className : ""}`}>
            <div className={`center center-vertical ${props.centerClassName ? props.centerClassName : ""}`}>
                {props.title ? <h2 className="title">{props.title}</h2> : null}
                {props.description ? <h3 className="description">{props.description}</h3> : null}
                {props.children}
            </div>
        </section>
    )
}

export default PageSection;