import React from 'react'
import './../resources/css/colors.css';
import './../resources/css/goToTopButton.css';
import { IoIosArrowUp } from 'react-icons/io';
import SimpleRoundIconButton from "./simpleRoundIconButton";
import { useState, useEffect } from 'react';
import useScrollDimensions from "../hooks/scrollDimensions";

function GoToTopButton(props) {

    const { scrollHeight } = useScrollDimensions();

    const [buttonClass, setButtonClass] = useState("");

    useEffect(() => {
        scrollHeight > 0 ? setButtonClass("visible") : setButtonClass("invisible");
    }, [buttonClass, setButtonClass, scrollHeight])

    return (
        <SimpleRoundIconButton onClick={() => window.scrollTo(0, 0)} className={`goToTopButton ${buttonClass}`}>
            <IoIosArrowUp size="30px" />
        </SimpleRoundIconButton>
    )
}

export default GoToTopButton;