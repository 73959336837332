import React, { useEffect, useState } from 'react'
import Bar from "./../components/bar";
import Footer from "./../components/footer";
import GoToTopButton from "./../components/goToTopButton";
import './../resources/css/template.css';
import toast, { Toaster } from 'react-hot-toast';
import { isLoggedIn } from "./../utils/methods";
import loading from './../resources/img/loading.gif';

function Template(props) {
  if(props.pageTitle){
      document.title="PressAndCo | "+props.pageTitle;
  }
  // lightMode is to remove header and footer (for webview in app)
    return (
        <>
          <div><Toaster/></div>
          <div className={`loading-container ${props.loading ? "loading-container-active" : ""}`}>
            <img src={loading} alt="loading" />
            <span>Chargement ...</span>
          </div>
          {props.liteMode ? <></> : <Bar connected={isLoggedIn()} />}
          <div className={`template-content  ${props.liteMode ? "template-content-liteMode" : ""}`}>
            {props.children}
          </div>
          {props.liteMode ? <></> : <Footer stringManager={props.stringManager} />}
          {props.liteMode ? <></> : <GoToTopButton />}
          
        </>
    )
}

export default Template;