import { API_URL } from "./config";
import { getAccount, getStoredAccount } from "./methods";

import toast from 'react-hot-toast';

// API
export const apiFetcher = (subRoute, method="GET", body=null, catchHandler=null, additionalHeaders = {}, parse=true) => {
    
      var myHeaders = new Headers();
      myHeaders.append("Accept", "application/json");
      myHeaders.append("Content-Type", "application/json");
      for (let key in additionalHeaders) {
          let value = additionalHeaders[key];
          myHeaders.append(key, value);
      }
      
      var requestOptions = {
        method: method,
        headers: myHeaders,
        redirect: 'follow'
      };

      if(body != null){
          requestOptions.body = JSON.stringify(body);
      }
      
      //API_URL+
      return fetch(API_URL+subRoute, requestOptions)
      .then(r =>  r.json().then(data => {
        if(r.status >= 400 && r.status < 600){
          let message = "Une erreur est survenue.";
          if(data.message){
            message = data.message;
          }
          else {
            switch(r.status){
              case 400:
                message = "Bad request";
                break;
              case 401:
                message = "Veuillez vous authentifier.";
                break;
              case 403:
                message = "Accès refusé.";
                break;
              case 404:
                message = "Contenu introuvable.";
                break;
              case 500:
                message = "Erreur serveur.";
                break;
            }
          }
          toast.error(message, {
            position: "bottom-center"
          });
          return Promise.reject();
        }
        return {status: r.status, body: data}
      }))
      .then(obj => obj)
      .catch(err => {
        console.log("Error while fetching : " + err);
        if(err.message){
          toast.error(err.message, {
            position: "bottom-center"
          });
        }
      });
}

export const apiFetcherLoggedIn = (subRoute, method="GET", body=null, catchHandler=null, additionalHeaders = {}, parse=true) => {
    additionalHeaders.Authorization = getStoredAccount().token;
    return apiFetcher(subRoute, method, body, catchHandler, additionalHeaders, parse);
}
