import Template from "../components/template";
import PageSection from "../components/pageSection";
import InputText from "../components/inputText";
import './../resources/css/global.css';
import './../resources/css/colors.css';
import './../resources/css/passwordLostToken.css';
import SimpleTextButton from "../components/simpleTextButton";

import React, { useState } from 'react';
import toast from 'react-hot-toast';


import { setStoredAccount, login, resetPassword, isPasswordValid } from "../utils/methods";
import StringsManager from "../utils/stringsManager";
import SplashScreen from "../components/splashScreen";


export default class PasswordLostToken extends React.Component {

  constructor(props) {
    super(props);
    this.stringManager = StringsManager.getInstance();
    this.state = {
      appIsReady: false,
      loading: false,
      password1: "",
      password2: "",
    }
  }

  componentDidMount() {
    const loadingStringsInterval = setInterval(() => {
      if(this.stringManager.isLoaded()){
        this.setState({appIsReady: true})
        clearInterval(loadingStringsInterval);
      }
    }, 50)
  }

  _resetPassword = () => {
    this.setState({loading: true});

    if(!isPasswordValid(this.state.password1)){
      toast.error('Mot de passe invalide. Il doit contenir au moins 8 caractères, une majuscule, une minuscule et un caractère numérique.', {
        position: "bottom-center"
      });
      this.setState({loading: false});
    }
    else if(this.state.password1.localeCompare(this.state.password2) !== 0){
      toast.error('Les mots de passe doivent correspondre.', {
        position: "bottom-center"
      });
      this.setState({loading: false});
    }
    else {
      let token = window.location.pathname.replace("/passwordLost/", "");

      resetPassword(token, this.state.password1)
      .then(res => {
        this.setState({loading: false, password1: "", password2: ""});
  
        toast.success(res.body.message, {
          position: "bottom-center"
        });
        setTimeout(() => window.location.href = "/", 1000);
      })
      .catch(err => this.setState({loading: false}))
    }
  }

  render() { 
    return !this.state.appIsReady ? <SplashScreen /> : (
      <Template loading={this.state.loading} stringManager={this.stringManager} pageTitle="Mot de passe perdu">

        <PageSection title="Mot de passe perdu" className="passwordLostToken-container" centerClassName="passwordLostToken-center">
          <InputText type="password" label="Mot de passe" onChange={(e) => this.setState({password1: e.target.value})} val={this.state.password1} />
          <InputText type="password" label="Confirmer le mot de passe" onChange={(e) => this.setState({password2: e.target.value})} val={this.state.password2} />

          <SimpleTextButton onClick={() => this._resetPassword()}>Réinitialiser le mot de passe</SimpleTextButton>
        </PageSection>
      
      </Template>
    )}
  }