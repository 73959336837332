import Template from "../components/template";
import PageSection from "../components/pageSection";
import './../resources/css/global.css';
import './../resources/css/colors.css';
import './../resources/css/orderConfirmed.css';
import HorizontalGroup from "../components/horizontalGroup";
import OrderProduct from "../components/orderProduct";
import ProductSection from "../components/productSection";
import VerticalGroup from "../components/verticalGroup";
import SimpleTextButton from "../components/simpleTextButton";

import { Link, useLocation } from "react-router-dom";
import { addToBasket, clearBasket, formatStringToPrint, getStoredAccount, getStoredBasket, mustBeLoggedIn, removeToBasket, roundPrice, timestampToStrDate } from "../utils/methods";
import { useState } from "react";
import { apiFetcherLoggedIn } from "../utils/fetcher";
import STRINGS from "../utils/strings";

import StringsManager from "../utils/stringsManager";
import SplashScreen from "../components/splashScreen";
import React, {useEffect} from "react";
import Spacer from "../components/spacer";

export default class OrderConfirmed extends React.Component {

  constructor(props) {
    super(props);
    this.stringManager = StringsManager.getInstance();
    this.state = {
      appIsReady: false,
      loading: false,
      orderInfos: null
    }

    this.account = getStoredAccount();
  }

  _loadOrderInfos = (orderId) => {
    this.setState({loading: true})
    apiFetcherLoggedIn("/orders/"+orderId, "GET")
      .then(orderResponse => {
        this.setState({loading: false, orderInfos: orderResponse.body})
      })
      .catch(e => this.setState({loading: false}));
  }

  componentDidMount() {
    mustBeLoggedIn();

    clearBasket();

    const loadingStringsInterval = setInterval(() => {
      if(this.stringManager.isLoaded()){
        this.setState({appIsReady: true})
        clearInterval(loadingStringsInterval);
      }
    }, 50)

    let orderId = window.location.pathname.replace("/order/confirmed/", "");
    if(orderId){
      this._loadOrderInfos(orderId);
    }
  }

  render() { 
    return !this.state.appIsReady ? <SplashScreen /> :
    <Template loading={this.state.loading} stringManager={this.stringManager} pageTitle="Commande confirmée">

      <PageSection title="Commande validée" className="orderConfirmed-container" centerClassName="orderConfirmed-center">
        {
          this.state.orderInfos ?
            <>
              {
                formatStringToPrint(this.stringManager.getMessage("ORDER_CONFIRMATION"), 
                  {"PICKUP_DATE": timestampToStrDate(this.state.orderInfos.pickUpDate), 
                  "DELIVERY_DATE": timestampToStrDate(this.state.orderInfos.deliveredDate)})
                .split('\n').map(str => <p className="content">{str}</p>)
              }
            </>
          :
            <>
              <p className="content">Chargement ...</p>
            </>
        }
        <SimpleTextButton onClick={() => window.location.href="/"}>Retour au site</SimpleTextButton>
        <Spacer />
        <SimpleTextButton onClick={() => window.location.href="/contact"}>Une question ?</SimpleTextButton>
        <Spacer />
        <SimpleTextButton onClick={() => window.location.href="/page/faq"}>Notre F.A.Q</SimpleTextButton>
      </PageSection>
    
    </Template>
  }
}