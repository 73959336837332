import Template from "../components/template";
import PageSection from "../components/pageSection";
import './../resources/css/global.css';
import './../resources/css/colors.css';
import './../resources/css/order.css';
import HorizontalGroup from "../components/horizontalGroup";
import OrderProduct from "../components/orderProduct";
import ProductSection from "../components/productSection";
import VerticalGroup from "../components/verticalGroup";
import SimpleTextButton from "../components/simpleTextButton";

import { Link } from "react-router-dom";
import { addToBasket, clearBasket, getStoredAccount, getStoredBasket, leadToOrdersClosed, mustBeLoggedIn, removeToBasket, roundPrice } from "../utils/methods";
import { apiFetcherLoggedIn } from "../utils/fetcher";

/*export default function Order() {

  useEffect(()=>{
    mustBeLoggedIn();
    _loadCatalog();
    _loadStoredBasket();
  } , [])

  useEffect(()=> {
    let totalPrice = 0;
    for(let article of basket){
      totalPrice = totalPrice + article.count * article.product.price;
    }
    setTotalPriceHT(totalPrice);
  } , [basket])

  return (*/

import StringsManager from "../utils/stringsManager";
import SplashScreen from "../components/splashScreen";
import React, {useEffect} from "react";

export default class Order extends React.Component {

  constructor(props) {
    super(props);
    this.stringManager = StringsManager.getInstance();
    this.state = {
      appIsReady: false,
      loading: false,
      catalog: [],
      basket: [],
      totalPriceTTC: 0,
      subscriptionRemainingMoney: 0
    }
    this.account = getStoredAccount();

    apiFetcherLoggedIn("/users/"+this.account.id, "GET").then(userInfos => {
      this.setState({
        subscriptionRemainingMoney: userInfos.body.subscriptionRemainingMoney,
      })
    });
  }

  _loadCatalog = () => {
    this.setState({loading: true, catalog: []});

    apiFetcherLoggedIn("/users/"+this.account.id+"/catalog", "GET")
      .then(answerCatalog => {
        this.setState({loading: false, catalog: answerCatalog.body});
      })
      .catch(err => this.setState({loading: false}))
  }

  _loadStoredBasket = () => {
    this.setState({basket: getStoredBasket()})
  }

  componentDidMount() {
    mustBeLoggedIn();
    this._loadCatalog();
    this._loadStoredBasket();
    const loadingStringsInterval = setInterval(() => {
      if(this.stringManager.isLoaded()){
        this.setState({appIsReady: true})
        clearInterval(loadingStringsInterval);
        
        leadToOrdersClosed(this.stringManager.getVariable("PRESSANDCO_OPEN"));
      }
    }, 50)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(JSON.stringify(prevState.basket) !== JSON.stringify(this.state.basket)){
      let totalPrice = 0;
      for(let article of this.state.basket){
        totalPrice = totalPrice + article.count * article.product.price;
      }
      this.setState({totalPriceTTC: totalPrice})
    }
  }

  render() { 
    let orderTTCPrice = roundPrice(this.state.totalPriceTTC);
    return !this.state.appIsReady ? <SplashScreen /> :
    <Template stringManager={this.stringManager} pageTitle="Commande">

      <PageSection title="Commander" className="order-container" centerClassName="order-center">
        <HorizontalGroup className="order-subcontainer">
          <main>
            <VerticalGroup className="order-productsections-container">
              <h3>Liste des produits</h3>
              { this.state.catalog.length > 0 ?
                (this.state.catalog.map((item, index) => (
                  <ProductSection key={item.id} title={item.name}>
                    { item.products.length > 0 ?
                      (item.products.map((itemProduct, index) => (
                        <OrderProduct
                          key={itemProduct.id}
                          name={itemProduct.product}
                          subtitle={itemProduct.details}
                          price={itemProduct.price}
                          minDelay={itemProduct.minDelay}
                          count={this.state.basket.filter(e => e.product.id == itemProduct.id).length > 0 ? this.state.basket.filter(e => e.product.id == itemProduct.id)[0].count : 0}
                          plusAction={() => {
                            addToBasket(itemProduct, 1);
                            this._loadStoredBasket();
                          }}
                          minusAction={() => {
                            removeToBasket(itemProduct, 1);
                            this._loadStoredBasket();
                          }}
                        />
                    )))
                    : 
                      <span>Aucun produit disponible.</span>
                    }
                  </ProductSection>
                )))
                : 
                <span>Votre catalogue de produit disponible est vide.</span>
              }
              {
                this.state.basket.length > 0 ?
                  <Link to="/order/summary" className="order-container-button-link"><SimpleTextButton>Valider</SimpleTextButton></Link>
                :
                <SimpleTextButton className="simpletextbutton-container-dark-gray">Valider</SimpleTextButton>
              }
            </VerticalGroup>
          </main>
          <aside>
            <VerticalGroup>
              <div className="order-container-basket-sticky-container">
                <h3>Panier</h3>
                {this.state.basket.length > 0 ?
                  (this.state.basket.map((item, index) => (
                      <article>
                        <p className="order-container-basket-article-name">{item.product.product}</p>
                        <p className="order-container-basket-article-quantity">{item.count}x</p>
                        <p className="order-container-basket-article-quantity">{roundPrice(item.count*item.product.price)}€</p>
                      </article>
                    )))
                  : 
                  <span>Votre panier est vide.</span>
                }
                <p className="order-container-basket-price"><strong>TOTAL</strong> : {roundPrice(orderTTCPrice)}€ TTC ({roundPrice(orderTTCPrice/1.2)}€ HT)</p>
                <p className="order-container-basket-price"><strong>SOLDE FIDÉLITÉ</strong> : {roundPrice(this.state.subscriptionRemainingMoney)}€</p>
                <p className="order-container-basket-price"><strong>RESTE À PAYER</strong> : {roundPrice(orderTTCPrice - Math.min(orderTTCPrice, this.state.subscriptionRemainingMoney))}€ TTC</p>
                <SimpleTextButton className="simpletextbutton-container-red" onClick={() => { clearBasket(); this._loadStoredBasket(); }}>Vider le panier</SimpleTextButton>
              {
                this.state.basket.length > 0 ?
                  <Link to="/order/summary" className="order-container-button-link"><SimpleTextButton>Valider</SimpleTextButton></Link>
                :
                <SimpleTextButton className="simpletextbutton-container-dark-gray">Valider</SimpleTextButton>
              }
              </div>
            </VerticalGroup>
          </aside>
        </HorizontalGroup>
      </PageSection>
    
    </Template>
  }

}