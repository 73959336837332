import { apiFetcher } from './fetcher';

export default class StringsManager {

  static myInstance = null;

  _MESSAGES = {
    TITLE1_HOME: 'Sélectionnez votre prestation de nettoyage',
    TITLE2_HOME: 'Nos prestations 24h/24 en 30 minutes chez vous',
    PRESSING_LBL: 'PRESSING',
    KILO_LBL: 'LINGE AU KILO',
    BLANCHISSERIE_LBL: 'BLANCHISSERIE',
    SNEAKERS_LBL: 'SNEAKERS',
    RETOUCHES_LBL: 'RETOUCHES',
    CUIR_LBL: 'PEAU ET CUIR',
    MA_COMMANDE: 'Ma commande',
    POURSUIVRE: 'POURSUIVRE MA COMMANDE',
    COMMENTAIRE: 'Commentaire sur la commande',
    RECAPITULATIF: 'Récapitulatif',
    DELAI_NORMAL: 'Normal (48h)',
    DELAI_EXPRESS: 'Express (24h) + 20€',
    DELAI_ALLONGE: 'Allongé (>48h)',  
    COMMANDE_VALIDEE: 'Votre commande est validée !',
    CONSEIL1: 'Votre livreur viendra récupérer vos vêtements le %PICKUP_DATE%, soyez prêt !',
    CONSEIL2: 'Merci de mettre vos vêtements dans un sac, avec votre nom.',
    CONSEIL3: 'Votre linge sera livré chez vous le %DELIVERY_DATE%, soyez disponible.',
    QUESTION: 'Une question ?',
  };
  _CONTENTS = {}
  _CONTENTS_CATEGORIES = {}
  _VARIABLES = {}
  _TIMETABLES = {}
  _messages_loaded = false;
  _contents_loaded = false;
  _content_categories_loaded = false;
  _variables_loaded = false;
  _timetables_loaded = false;

  constructor() {
    this._loadAllConfigMessages();
    this._loadAllContents();
    this._loadAllContentsCategories();
    this._loadVariables();
    this._loadTimetables();
  }


  /**
   * @returns {StringsManager}
   */
  static getInstance() {
      if (StringsManager.myInstance == null) {
        StringsManager.myInstance = new StringsManager();
      }

      return this.myInstance;
  }

  async _loadAllConfigMessages() {
    let allMessages = await apiFetcher("/config/message", "GET");
    for(let message of allMessages.body){
        this._MESSAGES[message.reference] = message.value;
    }
    this._messages_loaded = true;
  }

  async _loadAllContents() {
    let allContents = await apiFetcher("/websiteContents", "GET");
    for(let content of allContents.body){
        this._CONTENTS[content.slug] = content;
    }
    this._contents_loaded = true;
  }

  async _loadAllContentsCategories() {
    let allContentCategories = await apiFetcher("/websiteContentCategories", "GET");
    for(let category of allContentCategories.body){
        this._CONTENTS_CATEGORIES[category.id] = category.name;
    }
    this._content_categories_loaded = true;
  }

  async _loadVariables() {
    let isOpenVariable = await apiFetcher("/config/variable/specific/isOpen", "GET");
    let googleTokenVariable = await apiFetcher("/config/variable/specific/googleToken", "GET");
    //let fastDeliveryPriceVariable = await apiFetcher("/config/variable/specific/fastDeliveryPrice", "GET");

    this._VARIABLES[isOpenVariable.body.reference] = isOpenVariable.body;
    this._VARIABLES[googleTokenVariable.body.reference] = googleTokenVariable.body;
    //this._VARIABLES[fastDeliveryPriceVariable.body.reference] = fastDeliveryPriceVariable.body;
    
    this._variables_loaded = true;
  }

  async _loadTimetables() {
    let allTimetables = await apiFetcher("/config/timetable", "GET");
    for(let timetable of allTimetables.body){
        let pickup = {
          opening: {
            hour: parseInt(timetable.timetable_pickup_opening.split(":")[0]),
            minute: parseInt(timetable.timetable_pickup_opening.split(":")[1])
          },
          closing: {
            hour: parseInt(timetable.timetable_pickup_closing.split(":")[0]),
            minute: parseInt(timetable.timetable_pickup_closing.split(":")[1])
          }
        };
        let delivery = {
          opening: {
            hour: parseInt(timetable.timetable_delivery_opening.split(":")[0]),
            minute: parseInt(timetable.timetable_delivery_opening.split(":")[1])
          },
          closing: {
            hour: parseInt(timetable.timetable_delivery_closing.split(":")[0]),
            minute: parseInt(timetable.timetable_delivery_closing.split(":")[1])
          }
        };
        timetable.pickup = pickup;
        timetable.delivery = delivery;
        this._TIMETABLES[parseInt(timetable.id)] = timetable;
    }
    this._timetables_loaded = true;
  }

  _removeHtmlComponent(str) {
    return str.replaceAll("<p>", "").replaceAll("</p>", "");
  }

  getMessage(ref){
    if(ref in this._MESSAGES){
      return this._MESSAGES[ref];
    }
    return "";
  }

  getContent(ref){
    if(ref in this._CONTENTS){
      return this._CONTENTS[ref];
    }
    return "";
  }

  getCategory(id){
    if(id in this._CONTENTS_CATEGORIES){
      return this._CONTENTS_CATEGORIES[id];
    }
    return "";
  }

  getVariable(ref){
    if(ref in this._VARIABLES){
      return this._VARIABLES[ref];
    }
    return "";
  }

  getTimetable(dayIndex){
    if(dayIndex == 0){
      dayIndex = 7;
    }
    if(dayIndex in this._TIMETABLES){
      return this._TIMETABLES[dayIndex]
    }
    return {};
  }

  getContents(){
    return this._CONTENTS;
  }

  getCategories(){
    return this._CONTENTS_CATEGORIES;
  }

  getVariables(){
    return this._VARIABLES;
  }

  getTimetables(){
    return this._TIMETABLES;
  }

  isLoaded(){
    return this._contents_loaded && this._messages_loaded && this._content_categories_loaded && this._variables_loaded && this._timetables_loaded;
  }

}

