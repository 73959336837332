import { useState, useEffect } from 'react';

function getScrollDimensions() {
  return {
    scrollWidth: window.scrollX,
    scrollHeight: window.scrollY
  };
}

export default function useScrollDimensions() {
  const [scrollDimensions, setScrollDimensions] = useState(getScrollDimensions());

  useEffect(() => {
    function handleMovement() {
      setScrollDimensions(getScrollDimensions());
    }

    window.addEventListener('resize', handleMovement);
    window.addEventListener('scroll', handleMovement);
    return () => {
      window.removeEventListener('resize', handleMovement);
      window.removeEventListener('scroll', handleMovement);
    }
  }, []);

  return scrollDimensions;
}
