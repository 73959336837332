import React from 'react'
import './../resources/css/colors.css';
import './../resources/css/global.css';
import './../resources/css/header.css';
import { useState, useEffect } from 'react';
import useWindowDimensions from "../hooks/windowDimensions";

function Header(props) {
    const { height, width } = useWindowDimensions();

    const [headerClass, setHeaderClass] = useState("header1");

    const headerImages = {
        "header1": props.stringManager.getMessage("WEBSITE_HEADER_1_IMG"),
        "header2": props.stringManager.getMessage("WEBSITE_HEADER_2_IMG"),
        "header3": props.stringManager.getMessage("WEBSITE_HEADER_3_IMG"),
    }

    useEffect(() => {
        const interval = setInterval(() => {
        switch (headerClass) {
            case 'header1':
            setHeaderClass("header2")
            break;
            case 'header2':
            setHeaderClass("header3")
            break;
            default:
            setHeaderClass("header1")
        }      
        }, 1000*10);
        return () => clearInterval(interval);
    }, [headerClass, setHeaderClass]);
    
    return (
        <>
            <div style={{display: (headerClass == "header1") ? "block" : "none"}}>
                <header style={{
                width: width+"px",
                height: height+"px",
                backgroundColor: "black",
                backgroundImage: "url("+headerImages["header1"]+")",
                }}>

                    <div className="center center-vertical header-text-container">
                        <h1>{props.stringManager.getMessage("WEBSITE_HEADER_1")}</h1>
                        <p>{props.stringManager.getMessage("WEBSITE_HEADER_2")}{/*<em>*</em>*/}</p>

                        <h2>{props.stringManager.getMessage("WEBSITE_HEADER_3")}</h2>
                    </div>
                
                </header>
            </div>
            <div style={{display: (headerClass == "header2") ? "block" : "none"}}>
                <header style={{
                width: width+"px",
                height: height+"px",
                backgroundColor: "black",
                backgroundImage: "url("+headerImages["header2"]+")",
                }}>

                    <div className="center center-vertical header-text-container">
                        <h1>{props.stringManager.getMessage("WEBSITE_HEADER_1")}</h1>
                        <p>{props.stringManager.getMessage("WEBSITE_HEADER_2")}{/*<em>*</em>*/}</p>

                        <h2>{props.stringManager.getMessage("WEBSITE_HEADER_3")}</h2>
                    </div>
                
                </header>
            </div>
            <div style={{display: (headerClass == "header3") ? "block" : "none"}}>
                <header style={{
                width: width+"px",
                height: height+"px",
                backgroundColor: "black",
                backgroundImage: "url("+headerImages["header3"]+")",
                }}>

                    <div className="center center-vertical header-text-container">
                        <h1>{props.stringManager.getMessage("WEBSITE_HEADER_1")}</h1>
                        <p>{props.stringManager.getMessage("WEBSITE_HEADER_2")}{/*<em>*</em>*/}</p>

                        <h2>{props.stringManager.getMessage("WEBSITE_HEADER_3")}</h2>
                    </div>
                
                </header>
            </div>
        </>
    );
}

export default Header